import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { axiosBasicInstance } from 'libs/axios';
import { GET_END_USER_SCANNED_DATA_URL } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { RouteUrl } from 'utils/enum';
import { STATUS_CODE_500 } from 'utils/constants';

const useGetEndUserScannedData = (currentWeblink: any) => {
	const navigate = useNavigate();
	const { t } = useTranslation('endUserScan');
	const dispatch = useAppDispatch();

	const [endUserScanDataRes, setEndUserScanDataRes] = useState<any>({});
	const [endUserScanLoading, setEndUserScanLoading] = useState<boolean>(true);

	const params = {
		weblink: currentWeblink,
	};

	useEffect(() => {
		setEndUserScanLoading(true);
		axiosBasicInstance
			.post(GET_END_USER_SCANNED_DATA_URL, params)
			.then((response: any) => {
				setEndUserScanLoading(false);

				if (response?.output && response?.status) {
					setEndUserScanDataRes(response?.output);
				} else if (!response?.status && response?.statusCode === STATUS_CODE_500) {
					navigate(RouteUrl.PAGE_404);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch(() => {
				setEndUserScanLoading(false);
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		endUserScanDataRes,
		endUserScanLoading,
	};
};

export default useGetEndUserScannedData;
