import { FC, useCallback, useState, ChangeEvent, useEffect } from 'react';
import { Form, FormElement } from '@progress/kendo-react-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import passwordValidation, { formValidations } from 'utils/utils';
import CustomButton from 'components/common/button/CustomButton';
import CustomNotification from 'components/common/notification/CustomNotification';
import CustomError from 'components/common/errorLabel/CustomError';
import CustomSuccessModal from 'components/common/modals/CustomSuccessModal';
import { CONFIG_IDS, RouteUrl } from 'utils/enum';
import CustomErrorModal from 'components/common/modals/CustomErrorModal';
import CustomLoader from 'components/common/loader/CustomLoader';
import CustomInput from '../common/forms/CustomInput';
import useResetPassword from './hooks/useResetPassword';
import useValidateUserToken from './hooks/useValidateUserToken';
import ResetPasswordSkeleton from './skeleton/ResetPasswordSkeleton';

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const ResetPassword: FC = () => {
	const { t } = useTranslation('userModule');
	// States
	const [password, setPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [userID, setUserID] = useState<string>('');
	const [passwordError, setPasswordErr] = useState<string>('');
	const [confirmPasswordError, setconfirmPasswordError] = useState<string>('');
	const [userIDError, setUserIDError] = useState<string>('');
	const [passwordShow, setPasswordShow] = useState<boolean>(false);
	const [confirmPasswordShow, setConfirmPasswordShow] = useState<boolean>(false);
	const [resetParamData, setResetParamData] = useState<any>();
	const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [errorTitle, setErrorTitle] = useState<string>('');
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [tokenValidateParamData, setTokenValidateParamData] = useState<any>();
	const [tokenValidateModal, setTokenValidateModal] = useState<boolean>(false);

	const [passwordMinLength, setPasswordMinLength] = useState<number>(8);
	const [passwordMaxLength, setPasswordMaxLength] = useState<number>(16);
	const [passwordNumeric, setPasswordNumeric] = useState<boolean>(true);
	const [passwordCapital, setPasswordCapital] = useState<boolean>(true);
	const [passwordSpecialChar, setPasswordSpecialChar] = useState<boolean>(true);

	const navigate = useNavigate();
	const queryParams = useParams();

	// const userToken: string | null = getToken();

	// API call for Validate User Token
	const { validateresponseStatus, validateTokenloading, validateresponse } =
		useValidateUserToken(tokenValidateParamData);

	// API call for reset password
	const { resetPasswordResponse, resetPasswordLoading } = useResetPassword(resetParamData);

	useEffect(() => {
		setTokenValidateParamData({
			token: queryParams?.token,
			url: 'string',
			authToken: '',
		});
	}, [queryParams]);

	useEffect(() => {
		if (validateresponseStatus === false) {
			setTokenValidateModal(true);
		} else {
			if (validateresponse.passwordConfigurationList) {
				validateresponse.passwordConfigurationList.forEach((item: any) => {
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_MINLENGTH) {
						setPasswordMinLength(Number(item.keyValue));
					}
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_MAXLENGTH) {
						setPasswordMaxLength(Number(item.keyValue));
					}

					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_ONE_NUMERIC) {
						setPasswordNumeric(String(item.keyValue).toLowerCase() === 'true');
					}
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_ONE_SPECIAL_CHAR) {
						setPasswordSpecialChar(String(item.keyValue).toLowerCase() === 'true');
					}
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_ONE_CAPITAL_LETTER) {
						setPasswordCapital(String(item.keyValue).toLowerCase() === 'true');
					}
				});
			}
			setTokenValidateModal(false);
		}
	}, [validateresponseStatus, validateresponse]);

	useEffect(() => {
		if (resetPasswordResponse?.output?.resultId === 100) {
			setResetPasswordModal(resetPasswordResponse?.status);
		} else if (
			resetPasswordResponse?.output?.resultId === 102 ||
			resetPasswordResponse?.output?.resultId === 103
		) {
			setShowNotification(true);
			setErrorMsg(resetPasswordResponse?.output?.resultText);
			setErrorTitle(resetPasswordResponse?.errorMessage);
		} else if (resetPasswordResponse) {
			setShowNotification(true);
			setErrorMsg(resetPasswordResponse?.errorMessage);
			setErrorTitle(resetPasswordResponse?.statusMessage);
		}
	}, [resetPasswordResponse]);

	// Visibility toggle functions
	const togglePasswordVisiblity = () => {
		setPasswordShow((prev) => !prev);
	};

	const toggleConfirmPasswordVisiblity = () => {
		setConfirmPasswordShow((prev) => !prev);
	};

	// Handle change functions
	const handlePasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setPasswordErr('');
		setconfirmPasswordError('');
		setPassword(event.target.value ? event.target.value.toString() : '');
	}, []);

	const handleConfirmPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setconfirmPasswordError('');
		setConfirmPassword(event.target.value ? event.target.value.toString() : '');
	}, []);

	const handleUserIDChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setUserIDError('');
		setUserID(event.target.value ? event.target.value.toString() : '');
	}, []);

	// Handle submit function
	const handleSubmit = () => {
		// Validations
		const errorMessages: string = passwordValidation(password, {
			passwordMinLength,
			passwordMaxLength,
			passwordNumeric,
			passwordCapital,
			passwordSpecialChar,
		});

		const error: any = formValidations(
			{ userID },
			{
				userID: ['required', 'minlength', 'maxlength'],
			},
		);

		let confirmErrMsg = '';
		if (confirmPassword === '') {
			confirmErrMsg = t('CONFIRM_PASSWORD_EMPTY');
		} else if (password !== confirmPassword) {
			confirmErrMsg = t('CONFIRM_PASSWORD_NOT_MATCH');
		}
		setUserIDError(error.userID === undefined ? '' : error.userID);
		setPasswordErr(errorMessages);
		setconfirmPasswordError(confirmErrMsg);

		if (!errorMessages && !confirmErrMsg) {
			const params = {
				userName: userID,
				newPassword: confirmPassword,
				token: queryParams?.token,
			};
			setResetParamData(params);
		}
	};

	const handleClose = () => {
		navigate(RouteUrl.LOGINPAGE);
	};
	const handleNotificationClose = () => {
		setShowNotification(false);
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{validateTokenloading ? (
				// <div>Loading... </div>
				<ResetPasswordSkeleton />
			) : (
				<>
					<Form
						render={() => (
							<FormElement style={{ maxWidth: 650 }}>
								<fieldset className='k-form-fieldset'>
									<div className='form-group'>
										<CustomInput
											value={userID}
											onChange={handleUserIDChange}
											name='userID'
											label={t('USER_ID')!!}
											placeholder={t('USER_ID')!!}
											required
											type='text'
											className={
												userIDError !== '' ? 'input-validation-error' : ''
											}
										/>
										{userIDError !== '' && <CustomError value={userIDError} />}
									</div>

									<div className='form-group'>
										<CustomInput
											value={password}
											onChange={handlePasswordChange}
											name='password'
											label={t('NEW_PASSWORD')!!}
											placeholder={t('NEW_PASSWORD')!!}
											required
											type={passwordShow ? 'text' : 'password'}
											className={
												passwordError !== '' ? 'input-validation-error' : ''
											}
										/>
										{passwordError !== '' && (
											<CustomError value={passwordError} />
										)}
										<i
											className='show-password'
											role='button'
											onKeyDown={togglePasswordVisiblity}
											tabIndex={0}
											onClick={togglePasswordVisiblity}>
											{passwordShow ? eye : eyeSlash}
										</i>
									</div>
									<div className='form-group'>
										<CustomInput
											value={confirmPassword}
											onChange={handleConfirmPasswordChange}
											name='password'
											label={t('CONFIRM_PASSWORD')!!}
											placeholder={t('CONFIRM_PASSWORD')!!}
											required
											type={confirmPasswordShow ? 'text' : 'password'}
											className={
												confirmPasswordError !== ''
													? 'input-validation-error'
													: ''
											}
										/>
										{confirmPasswordError !== '' && (
											<CustomError value={confirmPasswordError} />
										)}
										<i
											className='show-password'
											role='button'
											onKeyDown={toggleConfirmPasswordVisiblity}
											tabIndex={0}
											onClick={toggleConfirmPasswordVisiblity}>
											{confirmPasswordShow ? eye : eyeSlash}
										</i>
									</div>
								</fieldset>
								<div className='k-form-buttons'>
									<CustomButton
										buttonClassName='btn btn-primary h-44px w-100'
										handleClick={handleSubmit}
										type='button'
										buttonName={
											resetPasswordLoading ? <CustomLoader /> : t('SUBMIT')
										}
									/>
								</div>
							</FormElement>
						)}
					/>
					{resetPasswordModal && (
						<CustomSuccessModal
							isModalVisible={resetPasswordModal}
							content={t('PASSWORD_CHANGE_SUCCESS_MESSAGE')}
							confirmClickNo={handleClose}
						/>
					)}
					{showNotification && (
						<CustomNotification
							message={errorMsg}
							title={errorTitle}
							onClose={handleNotificationClose}
							closable
							type={{ style: 'error', icon: true }}
						/>
					)}
					{tokenValidateModal && (
						<CustomErrorModal
							isModalVisible={tokenValidateModal}
							content={t('PASSWORD_RESET_LINK_ERROR_MESSAGE')}
							confirmClickNo={handleClose}
						/>
					)}
				</>
			)}
		</>
	);
};
export default ResetPassword;
