import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { utcFormat } from 'utils/utils';
// import { useAppSelector } from 'hooks/stateHooks';
// import { getDataFormat } from 'slices/configSlice';

import GridSkeleton from 'components/common/skeleton/GridSkeleton';

import ScanHistoryList from 'components/common/scanHistory/ScanHistoryList';
import { END_USER_TRANSLATION_KEY } from 'utils/constants';
import DownloadELeaflet from './DownloadELeaflet';

const ScannedProductDetails = ({
	productDetails,
	scanHistoryData,
	resultId,
	encProductId,
	scanHistoryLoading,
	fileName,
	statusFieldShow,
}: any) => {
	const { t } = useTranslation('endUserScan');
	const location = useLocation();
	// const getCurrentDate = useAppSelector(getDataFormat);

	const [urlUniqueProductId, setUrlUniqueProductId] = useState<string>('');

	useEffect(() => {
		/**
		 * URL slpits for get Unique product identification code
		 */
		const splitedUrl = location?.pathname?.split('/');
		/**
		 * Set Url Unique Product Id from url path name splitted
		 */
		setUrlUniqueProductId(splitedUrl[1]);
	}, [location]);

	return (
		<>
			{statusFieldShow && (
				<span
					className={
						productDetails?.productStatus ? 'sv-btn  bg-success' : 'sv-btn bg-danger'
					}>
					{/**
					 * Showing genuine product label if there is no URL prefix in setings
					 * and the valid condition is true
					 *  */}
					{/* eslint-disable-next-line no-nested-ternary */}
					{productDetails?.customWeblinkStatus && productDetails?.productStatus
						? t('GENUINE_PRODUCT_LABEL')
						: productDetails?.productStatus
						? t('VALID')
						: t('INVALID')}
				</span>
			)}

			<h3>{productDetails?.productName}</h3>
			{productDetails?.parentCompanyName && (
				<span className='company-name'>{productDetails?.parentCompanyName}</span>
			)}
			<p>{productDetails?.productDescription}</p>
			<div className='scanned-card-wrap'>
				<div className='row row-gutter-10px'>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('PRODUCT_CODE')}:`}</span>
							<span className='mfg-value-lbl'>
								{/**
								 * Showing urlUniqueProductId splitted from URL if there is no URL prefix in setings
								 * and only for NON GS1 condition (non gs1 checking in back-end)
								 *  */}
								{productDetails?.hideTenantPrefix
									? urlUniqueProductId
									: productDetails?.productCode || 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('GENERIC_NAME')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.genericName || 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('MANUFACTURER_ADDRESS')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.manufacturer}
								{productDetails?.manufacturer && <br />}
								{productDetails?.address || 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('MFG_DATE')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.manufacturingDate
									? utcFormat(
											productDetails.manufacturingDate,
											productDetails?.manufacturingAndExpiryDateFormat,
									  )?.toUpperCase()
									: 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('EXP_DATE')}:`}</span>
							<span className='mfg-value-lbl'>
								{/* {productDetails?.expiryDate
									? utcFormat(
											productDetails.expiryDate,
											productDetails?.dateFormat
												? productDetails?.dateFormat
												: getCurrentDate,
									  )
									: 'N/A'} */}
								{productDetails?.expiryDate
									? utcFormat(
											productDetails.expiryDate,
											productDetails?.manufacturingAndExpiryDateFormat,
									  )?.toUpperCase()
									: 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('LOT_NO')}:`}</span>
							<span className='mfg-value-lbl'>{productDetails?.lotNo || 'N/A'}</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('BRAND_NAME')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.brandName || 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('LICENSE_NO')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.licenseNumber || 'N/A'}
							</span>
						</div>
					</div>
					{/* <div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('SERIAL_NO')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.serialNumber || 'N/A'}
							</span>
						</div>
					</div> */}
					{/* {productDetails?.productGroup && (
						<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
							<div className='scanned-products-card'>
								<span className='mfg-lbl'>{`${t('PRODUCT_GROUP')}:`}</span>
								<span className='mfg-value-lbl'>
									{productDetails?.productGroup || 'N/A'}
								</span>
							</div>
						</div>
					)} */}
					{/* {productDetails?.quantity && (
						<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
							<div className='scanned-products-card'>
								<span className='mfg-lbl'>{`${t('QUANTITY')}:`}</span>
								<span className='mfg-value-lbl'>
									{productDetails?.quantity || 'N/A'}
								</span>
							</div>
						</div>
					)} */}
					{/* {productDetails?.productionDate && (
						<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
							<div className='scanned-products-card'>
								<span className='mfg-lbl'>{`${t('PRODUCTION_DATE')}:`}</span>
								<span className='mfg-value-lbl'>
									{productDetails?.productionDate
										? utcFormat(
												productDetails?.productionDate,
												productDetails?.dateFormat
													? productDetails?.dateFormat
													: getCurrentDate,
										  )
										: 'N/A'}
								</span>
							</div>
						</div>
					)} */}
					{/* {productDetails?.productCategory && (
						<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
							<div className='scanned-products-card'>
								<span className='mfg-lbl'>{`${t('PRODUCT_CATEGORY')}:`}</span>
								<span className='mfg-value-lbl'>
									{productDetails?.productCategory || 'N/A'}
								</span>
							</div>
						</div>
					)} */}
					{/* {productDetails?.productOrder && (
						<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
							<div className='scanned-products-card'>
								<span className='mfg-lbl'>{`${t('PRODUCT_ORDER')}:`}</span>
								<span className='mfg-value-lbl'>
									{productDetails?.productOrder || 'N/A'}
								</span>
							</div>
						</div>
					)} */}
					{/* {productDetails?.orderLocation && (
						<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
							<div className='scanned-products-card'>
								<span className='mfg-lbl'>{`${t('ORDER_LOCATION')}:`}</span>
								<span className='mfg-value-lbl'>
									{productDetails?.orderLocation || 'N/A'}
								</span>
							</div>
						</div>
					)} */}
				</div>

				{resultId === 100 && encProductId !== null && fileName !== null && (
					<div className='row row-gutter-10px'>
						<div className='col-lg-6 col-md-6 col-sm-6 gutter-10px'>
							<div className='download-product-file ms-0'>
								<DownloadELeaflet
									encProductId={encProductId}
									productName={productDetails.productName}
								/>
							</div>
						</div>
					</div>
				)}
			</div>

			{productDetails?.isEndUserScanEnabled && (
				<>
					<div className='scan-wrap'>
						<h4 className='scan-lbl'>{`${t('SCAN_HISTORY')}:`}</h4>
						<span className='scan-score'>
							{productDetails?.scanHistoryCount || 'N/A'}
						</span>
					</div>

					<div className='default-kendo-grid'>
						{scanHistoryLoading ? (
							<GridSkeleton />
						) : (
							<ScanHistoryList
								scanHistoryData={scanHistoryData}
								translationkey={END_USER_TRANSLATION_KEY}
							/>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default ScannedProductDetails;
