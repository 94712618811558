import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import FileFailedDropdownSkeleton from 'components/common/skeleton/FileFailedDropdownSkeleton';
import { RouteUrl } from 'utils/enum';
import CustomNoData from 'components/common/customNoData/CustomNoData';
import useGetFileFailureNotification from './hooks/useGetFileFailureNotification';
import ViewAllFileFailCard from './ViewAllFileFailCard';

const FileFailedDropdown = ({ notificationCount, hidePopover }: any) => {
	const { t } = useTranslation('fileFailure');

	const navigate = useNavigate();

	const [failedNotificationList, setFailedNotificationList] = useState<any>([]);

	const { fileFailureNotificationDataRes, fileFailureLoading } = useGetFileFailureNotification(
		false,
		null,
		null,
	);

	useEffect(() => {
		setFailedNotificationList(fileFailureNotificationDataRes);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileFailureNotificationDataRes]);

	const onDivClick = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const onEachNotificationClick = (e: any) => {
		return e;
	};

	// eslint-disable-next-line no-unused-vars
	const onViewMoreClick = (e: any) => {
		navigate(RouteUrl.FILE_FAILURE_LIST);
		hidePopover();
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{fileFailureLoading ? (
				<FileFailedDropdownSkeleton />
			) : (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
					<div onClick={(e: any) => onDivClick(e)}>
						<span>
							<h3>{`${t('FILES_FAILED')!!} (${
								// eslint-disable-next-line no-unsafe-optional-chaining
								notificationCount
							})`}</h3>
						</span>

						<ul className='inline-list-card file-failed-list'>
							{failedNotificationList && failedNotificationList.length > 0 ? (
								failedNotificationList.map((itm: any, index: number) => {
									return (
										// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
										<li
											onClick={(e: any) => onEachNotificationClick(e)}
											// eslint-disable-next-line react/no-array-index-key
											key={`${itm.requestId}${index}`}>
											{/* eslint-disable-next-line react/no-array-index-key */}
											<ViewAllFileFailCard item={itm} />
										</li>
									);
								})
							) : (
								<CustomNoData notificationType={t('FILE_FAILURE_NOTIFICATIONS')} />
							)}
						</ul>
					</div>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}

					<button
						type='button'
						onClick={(e: any) => onViewMoreClick(e)}
						className='btn view-all'>
						{t('VIEW_MORE')}
					</button>
				</>
			)}
		</>
	);
};

export default FileFailedDropdown;
