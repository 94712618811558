import moment from 'moment';
import i18next from 'i18next';

import { enUS, es, pt, fr, de, ko, uk } from 'date-fns/locale';
import { GridColumnMenuFilter, GridFilterOperators } from '@progress/kendo-react-grid';
import GridColumnMenu from 'components/common/grid/GridColumnMenu';

import {
	ALPHABETS_SPCLCHARACTERS,
	ALPHANUMERIC_REGEX,
	ALPHANUMERIC_WITH_HYPHEN_SLASH,
	ALPHANUMERIC_WITH_SPACE,
	ATLEAST_STRING_REGEX,
	AUTH_TOKEN_NAME,
	DECIMAL_REGEX,
	DEFAULT_PASSWORD,
	EMAIL_REGEX,
	MAX_LENGTH,
	MIN_LENGTH,
	NUMBERS_REGEX,
	PHONE_NUMBER,
	STRING_REGEX,
	STRING_REGEX_WITHOUT_SPACE,
	URL_REGEX,
	AUTH_REFRESH_TOKEN_NAME,
	SLASH_AND_BACKSLASH_REGEX,
} from './constants';
import { LANGUAGE_LIST, NOTIFICATION_TYPES, PASSWORD_VERIFICATION_ACTIONS } from './enum';

const passwordValidation = (password: string, validations: any) => {
	const passwordInputValue: string = password.trim();
	const DEFAULT_MIN_PASSWORD_LENGTH = validations.passwordMinLength;
	const DEFAULT_MAX_PASSWORD_LENGTH = validations.passwordMaxLength;
	const uppercaseRegExp = /(?=.*?[A-Z])/;
	// const lowercaseRegExp = /(?=.*?[a-z])/;
	const digitsRegExp = /(?=.*?[0-9])/;
	const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
	const passwordLength = passwordInputValue.length;
	const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
	// const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
	const digitsPassword = digitsRegExp.test(passwordInputValue);
	const specialCharPassword = specialCharRegExp.test(passwordInputValue);
	let errMsg = '';
	if (passwordLength === 0) {
		errMsg = i18next.t('FIELD_REQUIRED');
	} else if (!uppercasePassword && validations.passwordCapital) {
		errMsg = i18next.t('ATLEAST_ONE_UPPER');
	}
	// else if (!lowercasePassword) {
	// 	errMsg = i18next.t('ATLEAST_ONE_LOWER');
	// }
	else if (!digitsPassword && validations.passwordNumeric) {
		errMsg = i18next.t('ATLEAST_ONE_DIGIT');
	} else if (!specialCharPassword && validations.passwordSpecialChar) {
		errMsg = i18next.t('ATLEAST_ONE_SPECIAL');
	} else if (passwordLength < DEFAULT_MIN_PASSWORD_LENGTH) {
		errMsg = i18next.t('MINIMUM_LENGTH', { DEFAULT_MIN_PASSWORD_LENGTH });
	} else if (passwordLength > DEFAULT_MAX_PASSWORD_LENGTH) {
		errMsg = i18next.t('MAXIMUM_LENGTH', { DEFAULT_MAX_PASSWORD_LENGTH });
	} else {
		errMsg = '';
	}
	return errMsg;
};
export default passwordValidation;

export const passwordVerification = (value: string) => {
	if (value === DEFAULT_PASSWORD) {
		return true;
	}
	return false;
};

export const formValidations = (
	value: Record<string, string | null | any>,
	field: Record<string, string[]>,
) => {
	const errorObject: any = {};

	Object.keys(value).map((key) => {
		const valueLength = value[key]?.length;
		if (field[key]) {
			if (field[key].includes('required')) {
				if (value[key] === '' || value[key] === null) {
					errorObject[key] = i18next.t('FIELD_REQUIRED');
					return null;
				}
			}
			if (field[key].includes('maxlength') && value[key] !== '') {
				if (valueLength > MAX_LENGTH) {
					errorObject[key] = i18next.t('MAXIMUM_FORM_LENGTH', { MAX_LENGTH });
					return null;
				}
			}
			if (field[key].includes('minlength') && value[key] !== '') {
				if (valueLength < MIN_LENGTH) {
					errorObject[key] = i18next.t('MINIMUM_FORM_LENGTH', { MIN_LENGTH });
					return null;
				}
			}
			if (field[key].includes('alphanumeric') && value[key] !== '') {
				if (!ALPHANUMERIC_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}
			if (field[key].includes('email') && value[key] !== '') {
				if (!EMAIL_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_EMAIL');
					return null;
				}
			}
			if (field[key].includes('string') && value[key] !== '') {
				if (!STRING_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('STRING_ONLY');
				}
			}
			if (field[key].includes('negativeValue') && Number(value[key]) < 0) {
				errorObject[key] = i18next.t('NEGATIVE_VALUE');
				return null;
			}
			if (field[key].includes('numbers') && value[key] !== '') {
				if (!ALPHABETS_SPCLCHARACTERS.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}
			if (field[key].includes('alphanumericWithSpace') && value[key] !== '') {
				if (!ALPHANUMERIC_WITH_SPACE.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}
			if (field[key].includes('phonenumber') && value[key] !== '') {
				if (!PHONE_NUMBER.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_PHONE_NUMBER');
					return null;
				}
			}
			if (field[key].includes('reportNameSpecialCase') && value[key] !== '') {
				if (SLASH_AND_BACKSLASH_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}
		}
		return null;
	});
	return errorObject;
};

// To get token from local storage
export const getToken = () => {
	return localStorage.getItem(`${AUTH_TOKEN_NAME}`);
};
// To get refresh token from local storage
export const getRefreshToken = () => {
	return localStorage.getItem(`${AUTH_REFRESH_TOKEN_NAME}`);
};

// to save token in local storage
export const storeToken = (token: string) => {
	localStorage.removeItem(`${AUTH_TOKEN_NAME}`);
	localStorage.setItem(`${AUTH_TOKEN_NAME}`, token);
};
// to save refresh token in local storage
export const storeRefreshToken = (token: string) => {
	localStorage.removeItem(`${AUTH_REFRESH_TOKEN_NAME}`);
	localStorage.setItem(`${AUTH_REFRESH_TOKEN_NAME}`, token);
};
// to remove token from local storage
export const removeToken = () => {
	return localStorage.removeItem(`${AUTH_TOKEN_NAME}`);
};

// date format
export const formateDate = (date: Date | null, formate: string) => {
	const newDate = moment(date).format(formate.toUpperCase());
	// const newDate = moment(date).format(formate.replace('dd', 'DD'));
	return newDate;
};
/**
 * function to convert local date to utc
 * @param date
 * @param format
 * @returns formated
 */
export const formatDateToUtc = (date: Date | null, format: string) => {
	const newDate = moment(date);
	const formated = newDate.utc().format(format.toUpperCase());
	return formated;
};

// to format date with current selected language
export const formatDateWithCurrentLanguage = (date: any, neededFormat: string) => {
	const getLanguage = () => i18next.language;
	const locales = { enUS, es, pt, fr, de, ko, uk };
	// @ts-ignore
	const locale = locales[getLanguage()];
	// eslint-disable-next-line global-require
	const { format } = require('date-fns');
	const formattedDate = format(date, neededFormat, { locale });
	return formattedDate;
};

// to store Logined user data in local storage
export const storeLoginedUserData = (signedUserData: any) => {
	localStorage.removeItem('userData');
	localStorage.setItem('userData', signedUserData);
};

// To get Logined user data from local storage
export const getLoginedUserData = () => {
	const userDataJson: any = localStorage.getItem('userData');
	const data = JSON.parse(userDataJson);
	return data;
};

// to remove Logined user data from local storage
export const removeLoginedUserData = () => {
	return localStorage.removeItem('userData');
};

export const hasPrivileges = (privilgeIds: string, loginedUserData: any) => {
	// const data: any = getLoginedUserData();
	const permission: Array<string> = loginedUserData?.listPrivilege;
	if (privilgeIds && permission && permission.length > 0) {
		const filteredData: any = permission.filter((e: any) => e === privilgeIds);
		if (!filteredData.length) {
			return false;
		}

		return true;
	}

	return false;
};

/**
 *
 * @returns current base url
 */

export const getBaseUrl = () => {
	const baseUrl = window.location.origin;
	return baseUrl;
};

/**
 *
 * @returns latitude and longitude of browser, if permission granted
 */
export const getBrowserLocation = () => {
	return new Promise((resolve) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude } = position.coords;
					const { longitude } = position.coords;
					resolve({ latitude, longitude });
				},
				() => {
					const latitude = null;
					const longitude = null;
					resolve({ latitude, longitude });
					// reject(error);
				},
			);
		} else {
			const latitude = null;
			const longitude = null;
			resolve({ latitude, longitude });
			// reject(new Error('Geolocation is not supported by this browser.'));
		}
	});
};

export const hasValidUser = (types: any, loginedUserData: any) => {
	const userType: any = loginedUserData?.userType;

	if (types.includes(userType)) {
		return true;
	}

	return false;
};

export const sortUpandDown = (sortData: any) => {
	// const [sortData, setSortData] = useState<Array<SortDescriptor>>([
	//     { field: 'productName', dir: 'desc' },
	// ]);
	if (document.getElementsByClassName('chevron-arrow-up chevron_active').length > 0) {
		document
			.getElementsByClassName('chevron-arrow-up chevron_active')[0]
			.classList.remove('chevron_active');
	}

	if (document.getElementsByClassName('chevron-arrow-down chevron_active').length > 0) {
		document
			.getElementsByClassName('chevron-arrow-down chevron_active')[0]
			.classList.remove('chevron_active');
	}

	const dt = sortData;

	if (dt[0].dir === 'asc') {
		const input = document.getElementById(`arrow_asc_${dt[0].field}`) as HTMLElement | null;
		if (input != null) {
			input.classList.add('chevron_active');
		}
	} else {
		const input = document.getElementById(`arrow_desc_${dt[0].field}`) as HTMLElement | null;
		if (input != null) {
			input.classList.add('chevron_active');
		}
	}
};

export const findTotalDigit = (codeTypeName: any, gs1Prefix: any) => {
	const totalDigit = codeTypeName === 'GTIN' ? 13 : 17;

	const gs1PrefixL = gs1Prefix !== null ? gs1Prefix.toString().length : 0;
	return Number(totalDigit) - (gs1PrefixL + 1);
};

// product form validation
export const productFormValidations = (value: any, field: any) => {
	const errorObject: any = {};

	Object.keys(value).map((key) => {
		const valueLength = value[key]?.length;
		// for empty check of fields
		if (field[key]) {
			if (field[key].required) {
				if (typeof value[key] === 'string') {
					if (value[key].trim() === '') {
						errorObject[key] = i18next.t('FIELD_REQUIRED');
						return null;
					}
				} else if (value[key] === '' || value[key] === null) {
					errorObject[key] = i18next.t('FIELD_REQUIRED');
					return null;
				}

				// if (value[key] === '') {
				// 	errorObject[key] = i18next.t('FIELD_REQUIRED');
				// 	return null;
				// }
			}
			// for checking Maximum length
			if (field[key].maxlength && value[key] !== '') {
				const maxLength = field[key].maxlength;
				if (valueLength > field[key].maxlength) {
					errorObject[key] = i18next.t('MAXIMUM_FORM_LENGTH_PRODUCT', { maxLength });
					return null;
				}
			}
			// for checking negative values
			if (field[key].negativeValue && Number(value[key]) < 0) {
				errorObject[key] = i18next.t('NEGATIVE_VALUE');
				return null;
			}
			// for checking number fields
			if (field[key].numbers && value[key] !== '') {
				if (!NUMBERS_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('NUMBERS_ONLY');
					return null;
				}
			}
			// for checking decimal digits
			if (field[key].decimalValues && value[key] !== '') {
				if (!DECIMAL_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('NUMBERS_ONLY');
					return null;
				}
			}
			// for checking  alphanumeric charatcters including space
			if (field[key].alphanumericWithSpace && value[key] !== '') {
				if (!ALPHANUMERIC_WITH_SPACE.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}
			if (field[key].numbersOnly && value[key] !== '') {
				if (!NUMBERS_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}
			// for checking minimumlength
			if (field[key].minlength && value[key] !== '') {
				const minLength = field[key].minlength;
				if (valueLength < field[key].minlength) {
					errorObject[key] = i18next.t('MINIMUM_FORM_LENGTH_PRODUCT', { minLength });
					return null;
				}
			}
			// for checking alphabets only without space
			if (field[key].alphabetsWithoutSpace && value[key] !== '') {
				if (!STRING_REGEX_WITHOUT_SPACE.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}
			// for checking a valid URL
			if (field[key].urlOnly && value[key] !== '') {
				if (!URL_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}
			// for checking atleast one charecter
			if (field[key].atleastOneCharecter && value[key] !== '') {
				if (!ATLEAST_STRING_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}
			// for checking email
			if (field[key].emailOnly && value[key] !== '') {
				if (!EMAIL_REGEX.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_EMAIL');
					return null;
				}
			}
			// for checking  alphanumeric charatcters with hyphen and  forward slash
			if (field[key].alphanumericWithHyphenSlash && value[key] !== '') {
				if (!ALPHANUMERIC_WITH_HYPHEN_SLASH.test(value[key])) {
					errorObject[key] = i18next.t('INVALID_SYMBOL');
					return null;
				}
			}

			// for checking Maximum allowed value
			if (field[key].maxValue && value[key] !== '') {
				const maxValueData = field[key].maxValue;
				if (value[key] > field[key].maxValue) {
					errorObject[key] = i18next.t('MAXIMUM_ALLOWED_NUMBER', { maxValueData });
					return null;
				}
			}
			// for checking minimum allowed value
			if (field[key].minValue && value[key] !== '') {
				const minValueData = field[key].minValue;
				if (value[key] < field[key].minValue) {
					errorObject[key] = i18next.t('MINIMUM_ALLOWED_NUMBER', { minValueData });
					return null;
				}
			}
		}
		return null;
	});
	return errorObject;
};

// theme handling
export const themeHandling = (value: boolean) => {
	if (value) {
		document.body.classList.remove('light-theme');
		document.body.classList.add('dark-theme');
	} else {
		document.body.classList.remove('dark-theme');
		document.body.classList.add('light-theme');
	}
};

// filter identification
export const columnProps = (field: string, filter: any) => {
	const isColumnActive = GridColumnMenuFilter.active(field, filter);
	return {
		field,
		columnMenu: GridColumnMenu,
		headerClassName: isColumnActive ? 'active' : '',
	};
};

export const getFileFailureNotification = (data: any, currentDateFormat: any) => {
	const notificationMsg =
		data &&
		data.length > 0 &&
		data.map((item: any) => {
			return {
				text: `${
					item.actionDate
						? formateDate(item.actionDate, currentDateFormat)
						: i18next.t('UNKNOWN')!!
				}: ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT1')} ${
					item.fileName ? item.fileName : i18next.t('UNKNOWN')!!
				} ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT2')} ${
					item.connection ? item.connection : i18next.t('UNKNOWN')!!
				} ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT3')} ${
					item.actionDate
						? formateDate(item.actionDate, 'HH MM SS')
						: i18next.t('UNKNOWN')!!
				}. ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT4')} ${
					item.systemName ? item.systemName : i18next.t('UNKNOWN')!!
				} ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT5')} ${
					item.location ? item.location : i18next.t('UNKNOWN')!!
				}`,
			};
		});
	return notificationMsg;
};

export const convertNumberSystems = (value: any) => {
	// Nine Zeroes for Billions
	// eslint-disable-next-line no-nested-ternary
	return Math.abs(Number(value)) >= 1.0e9
		? `${(Math.abs(Number(value)) / 1.0e9).toFixed(2)}B`
		: // Six Zeroes for Millions
		// eslint-disable-next-line no-nested-ternary
		Math.abs(Number(value)) >= 1.0e6
		? `${(Math.abs(Number(value)) / 1.0e6).toFixed(2)}M`
		: // Three Zeroes for Thousands
		Math.abs(Number(value)) >= 1.0e3
		? `${(Math.abs(Number(value)) / 1.0e3).toFixed(2)}K`
		: Math.abs(Number(value));
};

// date utc format
export const utcFormat = (date: Date | null, formate: string) => {
	const newDate = moment.utc(date).local().format(formate.toUpperCase());
	// const newDate = moment.utc(date).local().format(formate.replace('dd', 'DD'));
	return newDate;
};
export const utcTime = (date: Date | null, enable24Hr: boolean) => {
	const newDate = moment
		.utc(date)
		.local()
		.format(enable24Hr ? 'HH:mm' : 'hh:mm A');
	return newDate;
};

// time format
export const timeFormat = (date: Date | null, enable24Hr: boolean) => {
	const newDate = moment(date).format(enable24Hr ? 'HH:mm' : 'hh:mm A');
	return newDate;
};

// Date substraction fuction
export const dateSubstract = (date: Date, days: number) => {
	date?.setDate(date.getDate() - days);
	return date;
};
export const dateAddition = (date: Date, days: number) => {
	date?.setDate(date.getDate() + days);
	return date;
};

export const getFileFailureNotificationSingle = (
	data: any,
	currentDateFormat: any,
	enable24Hr: boolean,
) => {
	const notificationMsg = `${
		data.actionDate ? utcFormat(data.actionDate, currentDateFormat) : i18next.t('UNKNOWN')!!
	}: ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT1')} ${
		data.fileName ? data.fileName : i18next.t('UNKNOWN')!!
	} ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT2')} ${
		data.connection ? data.connection : i18next.t('UNKNOWN')!!
	} ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT3')} ${
		data.actionDate ? utcTime(data.actionDate, enable24Hr) : i18next.t('UNKNOWN')!!
	}. ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT4')} ${
		data.systemName ? data.systemName : i18next.t('UNKNOWN')!!
	} ${i18next.t('FILE_FAILED_NOTIFICATION_TEXT5')} ${
		data.location ? data.location : i18next.t('UNKNOWN')!!
	}`;

	return notificationMsg;
};

export const getAwaitingActionSearch = (typeValue, item, enable24Hr) => {
	const { createdOn, remarks } = item;
	const customName1 = item?.customName1 ? item?.customName1 : '';
	const customName2 = item?.customName2 ? item?.customName2 : '';
	const customName3 = item?.customName3 ? item?.customName3 : '';
	const customName4 = item?.customName4 ? item?.customName4 : '';

	switch (typeValue) {
		/** Notification search text for user invalid login attempts */
		case NOTIFICATION_TYPES.USER_INVALID_LOGIN_ATTEMPT:
			return `${i18next.t('AN_UNAUTHORIZED_ACCESS_ATTEMPT_HAS_BEEN_DETECTED')} ${i18next.t(
				'AT',
			)} ${utcTime(createdOn, enable24Hr)} ${i18next.t('FROM')} ${customName3}. ${i18next.t(
				'THE_ATTEMPT_WAS_MADE_THROUGH_THE_BROWSER',
			)} ${customName2} ${i18next.t('USING_THE')} ${customName4}. ${i18next.t(
				'ATTEMPTED_USER_ID',
			)} ${customName1}.`;

		/** Notification search text when user email authentication fails */
		case NOTIFICATION_TYPES.EMAIL_AUTHENTICATION_FAILED:
			return `${i18next.t('AN_UNAUTHORIZED_ACCESS_ATTEMPT_HAS_BEEN_DETECTED')} ${i18next.t(
				'AT',
			)} ${utcTime(createdOn, enable24Hr)} ${i18next.t('FROM')} ${customName3}. ${i18next.t(
				'THE_ATTEMPT_WAS_MADE_THROUGH_THE_BROWSER',
			)}${customName2} ${i18next.t('USING_THE')} ${customName4}. ${i18next.t(
				'ATTEMPTED_EMAIL_ID',
			)} ${customName1}`;

		/** Notification search text when a report is returned */
		case NOTIFICATION_TYPES.REPORT_RETURN_NOTIFICATION:
			return `${i18next.t('THE')} ${customName1} ${i18next.t(
				'REPORT_REVIEW_RETURN',
			)} ${customName2}.`;

		/** Notification search text when a report is approved */
		case NOTIFICATION_TYPES.REPORT_APPROVE_NOTIFICATION:
			return `${i18next.t('THE')} ${customName1} ${i18next.t(
				'REPORT_APPROVED',
			)} ${customName2}.`;

		/** Notification search text when a product is reviewed */
		case NOTIFICATION_TYPES.PRODUCT_REVIEW_NOTIFICATION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;
		/** Notification search text when a product is approved */
		case NOTIFICATION_TYPES.PRODUCT_APPROVE_NOFICATION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;
		/** Notification search text when a product is deactivated */
		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t('THE_DEACTIVATION_OF_PRODUCT')} ${customName1} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;
		/** Notification search text when a product deactivation is approved */
		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t('THE_DEACTIVATION_OF_PRODUCT')} ${customName1} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;
		// case NOTIFICATION_TYPES.'Product_Activation_Notification':
		// 	return (
		//
		// 			The Product ${customName1} has been reviewed successfully by the reviewer.
		//
		// 	);
		/** Notification search text when a product is activated */
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t('THE_ACTIVATION_OF_PRODUCT')} ${customName1} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;
		/** Notification search text when a product activation is approved */
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t('THE_ACTIVATION_OF_PRODUCT')} ${customName1} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;

		// case NOTIFICATION_TYPES.'PO_Creation_Notification':
		// 	return (
		//
		// 			The Product ${customName1} has been reviewed successfully by the reviewer.
		//
		// 	);
		/** Notification search text when a product order is reviewed */
		case NOTIFICATION_TYPES.PO_REVIEW_NOTIFICATION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;
		/** Notification search text when a product order is approved */
		case NOTIFICATION_TYPES.PO_APPROVE_NOFICATION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;
		// case NOTIFICATION_TYPES.'PO_Deactivation_Notification':
		// 	return (
		//
		// 			The Product ${customName1} has been reviewed successfully by the reviewer.
		//
		// 	);
		/** Notification search text when a product order is deactivated */
		case NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t(
				'THE_DEACTIVATION_OF_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;

		/** Notification search text when a product order deactivation is approved */
		case NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t(
				'THE_DEACTIVATION_OF_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;

		/** Notification search text when a weblink is activated */
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_NOTIFICATION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;

		/** Notification search text when a weblink activation is reviewed */
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t('THE_WEBLINK_ACTIVATION')} ${customName1} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;

		/** Notification search text when a weblink activation is approved */
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t('THE_WEBLINK_ACTIVATION')} ${customName1} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;
		// case NOTIFICATION_TYPES.'Weblink_Deactivation_Notification':
		// 	return (
		//
		// 			The Product ${customName1} has been reviewed successfully by the reviewer.
		//
		// 	);
		/** Notification search text when a weblink deactivation is reviewed */
		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t('THE_WEBLINK_DEACTIVATION')} ${customName1} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;

		/** Notification search text when a weblink deactivation is approved */
		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t('THE_WEBLINK_DEACTIVATION')} ${customName1} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;
		// case NOTIFICATION_TYPES.'PO_Activation_Notification':
		// 	return (
		//
		// 			The Product ${customName1} has been reviewed successfully by the reviewer.
		//
		// 	);
		/** Notification search text when a product order activation is reviewed */
		case NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t(
				'THE_ACTIVATION_OF_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;

		/** Notification search text when a product order activation is approved */
		case NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t(
				'THE_ACTIVATION_OF_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;

		// Awaiting Action Text

		/** Notification search text when a product is reviewed */
		case NOTIFICATION_TYPES.PRODUCT_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'CREATED_AND_WAITING_FOR_REVIEW',
			)}`;

		/** Notification search text when a product is approved */
		case NOTIFICATION_TYPES.PRODUCT_APPROVE_NOFICATION_ACTION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'CREATED_AND_WAITING_FOR_APPROVAL',
			)}`;

		/** Notification search text when a product activation is approved */
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t('ACTIVATION_INITIATED_FOR_THE_PRODUCT')} ${customName1} ${i18next.t(
				'WAITING_FOR_THE_APPROVAL',
			)}`;

		/** Notification search text when a product activation is reviewed */
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t('ACTIVATION_INITIATED_FOR_THE_PRODUCT')} ${customName1} ${i18next.t(
				'WAITING_FOR_THE_REVIEW',
			)}`;

		/** Notification search text when a product deactivation is approved */
		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_INITIATED_FOR_THE_PRODUCT',
			)} ${customName1} ${i18next.t('WAITING_FOR_THE_APPROVAL')}`;

		/** Notification search text when a product deactivation is reviewed */
		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_INITIATED_FOR_THE_PRODUCT',
			)} ${customName1} ${i18next.t('WAITING_FOR_THE_REVIEW')}`;

		/** Notification search text when a product order is approved */
		case NOTIFICATION_TYPES.PO_APPROVE_NOFICATION_ACTION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'CREATED_AND_WAITING_FOR_APPROVAL',
			)}`;
		/** Notification search text when a product order is reviewed */
		case NOTIFICATION_TYPES.PO_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'CREATED_AND_WAITING_FOR_REVIEW',
			)}`;
		/** Notification search text when a product order activation is reviewed */
		case NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t(
				'ACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('WAITING_FOR_THE_REVIEW')}`;
		/** Notification search text when a product order activation is approved */
		case NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t(
				'ACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('WAITING_FOR_THE_APPROVAL')}`;
		/** Notification search text when a product order deactivation is reviewed */
		case NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('WAITING_FOR_THE_REVIEW')}`;
		/** Notification search text when a product order deactivation is approved */
		case NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('WAITING_FOR_THE_APPROVAL')}`;
		/** Notification search text when a weblink activation is approved */
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t('ACTIVATION_INITIATED_FOR_THE_PRODUCT')} ${customName1} ${i18next.t(
				'WEBLINK_AND_WAITING_FOR_APPROVAL',
			)}`;
		/** Notification search text when a weblink activation is reviewed */
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t('ACTIVATION_INITIATED_FOR_THE_PRODUCT')} ${customName1} ${i18next.t(
				'WEBLINK_AND_WAITING_FOR_REVIEW',
			)}`;
		/** Notification search text when a weblink deactivation is approved */
		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_INITIATED_FOR_THE_PRODUCT',
			)} ${customName1} ${i18next.t('WEBLINK_AND_WAITING_FOR_APPROVAL')}`;
		/** Notification search text when a weblink deactivation is reviewed */
		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_INITIATED_FOR_THE_PRODUCT',
			)} ${customName1} ${i18next.t('WEBLINK_AND_WAITING_FOR_REVIEW')}`;
		/** Notification search text when a weblink activation is reviewed */
		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t(
				'ACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('WEBLINK_AND_WAITING_FOR_REVIEW')}`;
		/** Notification search text when a po weblink activation is approved */
		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t(
				'ACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('WEBLINK_AND_WAITING_FOR_APPROVAL')}`;
		/** Notification search text when a po weblink deactivation is approved */
		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('WEBLINK_AND_WAITING_FOR_APPROVAL')}`;
		/** Notification search text when a po weblink deactivation is reviewed */
		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_INITIATED_FOR_THE_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('WEBLINK_AND_WAITING_FOR_REVIEW')}`;
		/** Notification search text when a po weblink activation is reviewed */
		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_ACTIVATION_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;
		/** Notification search text when a po weblink activation is approved */
		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_ACTIVATION_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;
		/** Notification search text when a po weblink deactivation is reviewed */
		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_DEACTIVATION_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER',
			)}`;
		/** Notification search text when a po weblink deactivation is approved */
		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_DEACTIVATION_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER',
			)}`;
		/** Notification search text when a po weblink review is rejected */
		case NOTIFICATION_TYPES.PO_WEBLINK_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_ACTIVATION_REVIEW_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('REJECTED_BY_REVIEWER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a po weblink reject is approved */
		case NOTIFICATION_TYPES.PO_WEBLINK_APPROVE_REJECT_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_ACTIVATION_REVIEW_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('REJECTED_BY_APPROVER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a po weblink deactivation review is rejected */
		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_DEACTIVATION_REVIEW_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('REJECTED_BY_REVIEWER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a po weblink deactivation approval is rejected */
		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_DEACTIVATION_REVIEW_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('REJECTED_BY_APPROVER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a po weblink activation is rejected */
		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_ACTIVATION_REVIEW_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('REJECTED_BY_REVIEWER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a po weblink activation is rejected by approver */
		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t(
				'WEBLINK_ACTIVATION_REVIEW_PRODUCT_ORDER',
			)} ${customName1} - ${customName2} ${i18next.t('REJECTED_BY_APPROVER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a po review is rejected */
		case NOTIFICATION_TYPES.PO_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'RETURN_BY_REVIEWER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a po is returned by approver */
		case NOTIFICATION_TYPES.PO_APPROVE_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'RETURN_BY_APPROVER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a weblink is returned by reviewer */
		case NOTIFICATION_TYPES.WEBLINK_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('THE_WEBLINK')} ${customName1} ${i18next.t(
				'RETURN_BY_REVIEWER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when weblink deactivation is rejected by reviewer */
		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('WEBLINK_DEACTIVATION_REVIEW_OF')} ${customName1} ${i18next.t(
				'RETURN_BY_APPROVER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a weblink deactivation is reject is approved */
		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t('WEBLINK_DEACTIVATION_REVIEW_OF')} ${customName1} ${i18next.t(
				'RETURN_BY_APPROVER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a  weblink activation is review is rejected */
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('WEBLINK_ACTIVATION_REVIEW_OF')} ${customName1} ${i18next.t(
				'RETURN_BY_REVIEWER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a  weblink activation is approval is rejected */
		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t('WEBLINK_ACTIVATION_REVIEW_OF')} ${customName1} ${i18next.t(
				'RETURN_BY_APPROVER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a po weblink approve is rejected */
		case NOTIFICATION_TYPES.WEBLINK_APPROVE_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'RETURN_BY_APPROVER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a product approve is rejected */
		case NOTIFICATION_TYPES.PRODUCT_APPROVE_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'RETURN_BY_APPROVER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a po weblink deactivation review is rejected */
		case NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'DEACTIVATION_REVIEWER_RETURNED',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a po deactivation  approval is rejected */
		case NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'DEACTIVATION_APPROVER_RETURNED',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a po activation review  is rejected */
		case NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'ACTIVATION_REVIEWER_RETURNED',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a po activation rejection is approved */
		case NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCR_ORDER')} ${customName1} - ${customName2} ${i18next.t(
				'ACTIVATION_APPROVER_RETURNED',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a po activation approver returned */
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'ACTIVATION_APPROVER_RETURNED',
			)}${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a product activation is returned by reviewer */
		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'ACTIVATION_REVIEWER_RETURNED',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a product review is returned by reviewer */
		case NOTIFICATION_TYPES.PRODUCT_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'RETURN_BY_REVIEWER',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a product deactivation is returned by reviewer */
		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'DEACTIVATION_REVIEWER_RETURNED',
			)} ${i18next.t('REMARKS')} ${remarks}`;
		/** Notification search text when a po deactivation approval is rejected */
		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t('THE_PRODUCT')} ${customName1} ${i18next.t(
				'DEACTIVATION_APPROVER_RETURNED',
			)} ${i18next.t('REMARKS')} ${remarks}`;

		/**
		 * report text search
		 */

		/** Notification search text when a report is generated and waiting for review */
		case NOTIFICATION_TYPES.REPORT_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t('THE')} ${customName1} ${i18next.t(
				'REPORT_GENERATED_AND_WAITING_FOR_REVIEW',
			)}`;
		/** Notification search text when a report is reviewed */
		case NOTIFICATION_TYPES.REPORT_REVIEW_NOTIFICATION:
			return `${i18next.t('THE')} ${customName1} ${i18next.t(
				'REPORT_REVIEWED',
			)} ${customName2}.`;
		/** Notification search text when a report is reviewed and waiting for approval */
		case NOTIFICATION_TYPES.REPORT_APPROVE_NOFICATION_ACTION:
			return `${i18next.t('THE')} ${customName1} ${i18next.t(
				'REPORT_REVIEWED_AND_WAITING_FOR_APPROVAL',
			)}`;
		/** Notification search text when a weblink activation is initiated and waiting for review */
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_NOTIFICATION:
			return `${i18next.t('ACTIVATION_INITIATED_FOR_THE_WEBLINK')} ${customName1} ${i18next.t(
				'AND_WAITING_FOR_REVIEW',
			)}`;
		/** Notification search text when a weblink reactivation is reviewed */
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t('THE_REACTIVATION_OF')} ${customName1} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER_FOR_THE_PRODUCT',
			)} ${customName2}`;
		/** Notification search text when a weblink reactivation is approved */
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t('THE_REACTIVATION_OF')} ${customName1} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER_FOR_THE_PRODUCT',
			)} ${customName2}`;
		/** Notification search text when a weblink deactivation iand waiting for approval */
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_NOTIFICATION:
			return `${i18next.t(
				'DEACTIVATION_INITIATED_FOR_THE_WEBLINK',
			)} ${customName1} ${i18next.t('AND_WAITING_FOR_APPROVE')} ${customName2}`;
		/** Notification search text when a weblink deactivation is reviewed */
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION:
			return `${i18next.t('THE_DEACTIVATION_OF')} ${customName1} ${i18next.t(
				'HAS_BEEN_REVIEWED_SUCCESSFULLY_BY_THE_REVIEWER_FOR_THE_PRODUCT',
			)} ${customName2}`;
		/** Notification search text when a weblink deactivation is approved */
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION:
			return `${i18next.t('THE_DEACTIVATION_OF')} ${customName1} ${i18next.t(
				'HAS_BEEN_APPROVED_SUCCESSFULLY_BY_THE_APPROVER_FOR_THE_PRODUCT',
			)} ${customName2}`;
		/** Notification search text when a weblink deactivation is rejected */
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('THE_DEACTIVATION_REVIEW_OF')} ${customName1} ${i18next.t(
				'FOR_THE_PRODUCT',
			)} ${customName2} ${i18next.t('REJECTED_BY_REVIEWER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a weblink deactivation approval is rejected */
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t('THE_DEACTIVATION_APPROVE_OF')} ${customName1} ${i18next.t(
				'FOR_THE_PRODUCT',
			)} ${customName2} ${i18next.t('REJECTED_BY_APPROVER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a weblink reactivation is rejected by reviewer */
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
			return `${i18next.t('THE_REACTIVATION_REVIEW_OF')} ${customName1} ${i18next.t(
				'FOR_THE_PRODUCT',
			)} ${customName2} ${i18next.t('REJECTED_BY_REVIEWER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a weblink activation approval is rejected */
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
			return `${i18next.t('THE_REACTIVATION_APPROVE_OF')} ${customName1} ${i18next.t(
				'FOR_THE_PRODUCT',
			)} ${customName2} ${i18next.t('REJECTED_BY_APPROVER')} ${i18next.t(
				'REMARKS',
			)} ${remarks}`;
		/** Notification search text when a weblink reactivation is initiated and waiting for review */
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t(
				'REACTIVATION_IS_INITIATED_FOR_THE_WEBLINK',
			)} ${customName1} ${i18next.t('OF_PRODUCT')}} ${customName2} ${i18next.t(
				'AND_WAITING_FOR_REVIEW',
			)}}`;
		/** Notification search text when a weblink deactivation review is pending  */
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_IS_INITIATED_FOR_THE_WEBLINK',
			)}} ${customName1} ${i18next.t('OF_PRODUCT')}} ${customName2} ${i18next.t(
				'AND_WAITING_FOR_REVIEW',
			)}}`;
		/** Notification search text when a weblink activation approval is pending */
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t(
				'REACTIVATION_IS_INITIATED_FOR_THE_WEBLINK',
			)}} ${customName1} ${i18next.t('OF_PRODUCT')}} ${customName2} ${i18next.t(
				'AND_WAITING_FOR_APPROVE',
			)}}`;
		/** Notification search text when a weblink deactivation is pending for approval */
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
			return `${i18next.t(
				'DEACTIVATION_IS_INITIATED_FOR_THE_WEBLINK',
			)}} ${customName1} ${i18next.t('OF_PRODUCT')}} ${customName2} ${i18next.t(
				'AND_WAITING_FOR_APPROVE',
			)}}`;
		/** Notification search text of an epcis file recieved details */
		case NOTIFICATION_TYPES.EPCIS_FILE_SUCCESS:
			return `${i18next.t('EPCIS_FILE')} ${customName1} ${i18next.t(
				'SENT_THROUGH',
			)} ${customName2}} ${i18next.t('SUCCESFULLY_RECIEVED')} ${customName3} ${i18next.t(
				'AT',
			)} ${utcTime(createdOn, enable24Hr)} ${i18next.t('FROM_LOCATION')} ${customName4}.`;
		/** Notification search text of an epcis file failed to recieve details */
		case NOTIFICATION_TYPES.EPCIS_FILE_FAILED:
			return `${i18next.t('EPCIS_FILE')} ${customName1} ${i18next.t(
				'SENT_THROUGH',
			)}  ${customName2} ${i18next.t('FAILED_RECEIVED')} ${customName3} ${i18next.t(
				'AT',
			)} ${utcTime(createdOn, enable24Hr)} ${i18next.t('FROM_LOCATION')} ${customName4}.`;
		/** Notification search text of password expiry notification */
		case NOTIFICATION_TYPES.PASSWORD_NOTIFICATION:
			return `${i18next.t('PASSWORD_EXPIRE_IN')} ${
				customName1 === 0 ? i18next.t('TODAY') : customName1
			} ${i18next.t('PASSWORD_EXPIRE_TEXT')}`;

		default:
			return `${i18next.t('UNWANTED_MESSAGE')}`;
	}
};

// eslint-disable-next-line consistent-return
export const getPasswordVerificationAction = (item, reject = false) => {
	// eslint-disable-next-line default-case
	switch (item?.type) {
		case NOTIFICATION_TYPES.PRODUCT_APPROVE_NOFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.APPROVE;
		}

		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_APPROVE;
		}

		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_APPROVE;
		}

		case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_REVIEW;
		}

		case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_REVIEW;
		}

		case NOTIFICATION_TYPES.PRODUCT_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.REVIEW;
		}

		case NOTIFICATION_TYPES.PO_APPROVE_NOFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.APPROVE;
		}

		case NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_APPROVE;
		}

		case NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_APPROVE;
		}

		case NOTIFICATION_TYPES.PO_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.REVIEW;
		}

		case NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_REVIEW;
		}

		case NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_REVIEW;
		}

		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_APPROVE;
		}

		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_APPROVE;
		}

		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_APPROVE;
		}

		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_APPROVE;
		}

		case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_REVIEW;
		}

		case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_REVIEW;
		}

		case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_REVIEW;
		}

		case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_REVIEW;
		}
		case NOTIFICATION_TYPES.REPORT_APPROVE_NOFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.APPROVE;
		}
		case NOTIFICATION_TYPES.REPORT_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.REVIEW;
		}
		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_REVIEW;
		}
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_REVIEW;
		}

		case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.ACTIVATION_APPROVE;
		}
		case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION: {
			if (reject) {
				return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_RETURN;
			}
			return PASSWORD_VERIFICATION_ACTIONS.DEACTIVATION_APPROVE;
		}
	}
};

// download file as blob
export const downloadFile = (fileData: any, fileName: string) => {
	const blob = new Blob([fileData], { type: 'application/pdf' });
	const fileUrl = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = fileUrl;
	link.download = fileName;
	link.target = '_blank';
	link.rel = 'noopener noreferrer';
	link.click();
	URL.revokeObjectURL(fileUrl);
};

/** used to get users locale value from browser */
export const getUserLocale = () => {
	if (navigator.language) {
		return navigator.language;
	}
	if (navigator.languages && navigator.languages.length > 0) {
		return navigator.languages[0];
	}
	return 'en-US'; // Default to a fallback locale
};

/** used to switch languages with users locale values */
export const changeLanguage = (locale: string) => {
	return i18next.changeLanguage(
		LANGUAGE_LIST?.filter((item: any) => item.locale === locale)[0]?.locale,
	);
};

export const setUserSavedLanguage = (savedLanguageId: string) => {
	return i18next.changeLanguage(
		LANGUAGE_LIST?.filter((item: any) => item.encLanguageId === savedLanguageId)[0]?.locale,
	);
};

/** used for custom filtering of STATE field in all grid components */
export const customFilteringFunction = (
	filter: any,
	filterValue: any,
	fieldString: string,
	setFilter: any,
) => {
	const tempFilter = { ...filter };

	if (filterValue) {
		let isAlreadyIn: number | null = null;
		if (filter) {
			filter?.filters.forEach((item: any, index: number) => {
				if (item.filters?.length > 0) {
					item.filters.forEach((val: any) => {
						if (val.field === fieldString) {
							isAlreadyIn = index;
						}
					});
				}
			});
			if (isAlreadyIn !== null && isAlreadyIn >= 0) {
				tempFilter?.filters.splice(isAlreadyIn, 1, filterValue);
			} else {
				tempFilter?.filters.push(filterValue);
			}

			setFilter(tempFilter);
		} else {
			setFilter({
				filters: [
					{
						logic: 'and',
						filters: [filterValue.filters[0]],
					},
				],
				logic: 'and',
			});
		}
	} else if (filter) {
		filter?.filters.forEach((item: any, index: number) => {
			if (item.filters?.length > 0) {
				item.filters.forEach((val: any) => {
					if (val.field === fieldString) {
						tempFilter.filters.splice(index, 1);
					}
				});
			}
		});

		setFilter(tempFilter);
	}
};

// date format for api passing
export const formateDateForApi = (date: Date | null, formate: string) => {
	const newDate = moment(date).format(formate);
	return newDate;
};

// convert file to base64
export const convertToBase64 = (file) => {
	return new Promise((resolve) => {
		let baseURL: any = '';
		// Make new FileReader
		const reader = new FileReader();

		// Convert the file to base64 text
		reader?.readAsDataURL(file);

		// on reader load somthing...
		reader.onload = () => {
			// Make a fileInfo Object

			baseURL = reader?.result;

			resolve(baseURL);
		};
	});
};

/** \
 * Function to download blob object in CSV format
 */
export const downloadCSVFile = (fileData: any, fileName: string) => {
	// download file
	const blob: Blob = new Blob([fileData], {
		type: 'text/csv',
	});

	const url: string = window.URL.createObjectURL(blob);
	const link: HTMLAnchorElement = document.createElement('a');
	link.href = url;
	link.download = `${fileName}`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	window.URL.revokeObjectURL(url);
};

export const downloadFileCsv = (fileData: any, fileName: string) => {
	const blob = new Blob([fileData], { type: 'text/csv' });
	const fileUrl = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = fileUrl;
	link.download = fileName;
	link.target = '_blank';
	link.rel = 'noopener noreferrer';
	link.click();
	URL.revokeObjectURL(fileUrl);
};

export const filterOperators: GridFilterOperators = {
	text: [
		{ text: 'grid.filterContainsOperator', operator: 'contains' },
		{ text: 'grid.filterStartsWithOperator', operator: 'startswith' },
		// { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
	],
	boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
	numeric: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
	// date: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
};

// Utility function to download a file
export const downloadFileWithType = (
	fileData: any,
	fileName: string,
	fileType: 'pdf' | 'csv' | 'zip',
) => {
	// Create a Blob object from the file data
	// Set the MIME type based on fileType
	let blob;

	if (fileType === 'pdf') {
		blob = new Blob([fileData], { type: 'application/pdf' });
	} else if (fileType === 'csv') {
		blob = new Blob([fileData], { type: 'text/csv' });
	} else if (fileType === 'zip') {
		blob = new Blob([fileData], { type: 'application/zip' });
	}

	// Create a URL for the Blob object
	const fileUrl = URL.createObjectURL(blob);

	// Create a temporary link element
	const link = document.createElement('a');

	// Set link url to the Blob URL
	link.href = fileUrl;

	// Set download filename
	link.download = `${fileName}.${fileType}`;

	// Trigger download by clicking the link
	link.click();

	// Revoke the Object URL to release memory
	URL.revokeObjectURL(fileUrl);
};

/**
 * function to prevent string repeatation
 *
 * @param inputString input string to check the char repeatatoin
 */
export const getUniqueString = (inputString) => {
	const charSet = new Set();
	let uniqueString = '';

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < inputString.length; i++) {
		const char = inputString?.charAt(i);
		if (!charSet.has(char.toLowerCase()) && !charSet.has(char.toUpperCase())) {
			uniqueString += char;
			charSet.add(char);
		}
	}
	return uniqueString;
};
