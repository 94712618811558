import { useEffect, useState } from 'react';

import { axiosPrivateInstance } from 'libs/axios';

import { GET_ALL_PARENT_LOCATION_URL } from 'utils/serviceUrls';

const useGetAllParentLocation = () => {
	const [parentLocationResponseData, setParentLocationResponseData] = useState<any>([]);
	const [parentLocationloading, setParentLocationLoading] = useState<boolean>(true);

	const ApiRespone = async () => {
		setParentLocationLoading(true);

		// eslint-disable-next-line no-unused-vars
		await axiosPrivateInstance
			.post(GET_ALL_PARENT_LOCATION_URL)
			.then((response: any) => {
				setParentLocationResponseData(response.output);
				setParentLocationLoading(false);
			})
			.catch(() => {
				setParentLocationLoading(false);
			});
	};

	useEffect(() => {
		ApiRespone();
	}, []);

	useEffect(() => {
		ApiRespone();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		parentLocationResponseData,
		parentLocationloading,
	};
};

export default useGetAllParentLocation;
