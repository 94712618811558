import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_APPROVE_STATUS } from 'utils/serviceUrls';

const updateApproveStatusService = async (
	id: string,
	message: string,
	isProductUpdate: boolean,
) => {
	const paramData = {
		url: 'string',
		agent: 'string',
		encProductId: id,
		statusId: 4,
		comment: message,
		isProductStatus: isProductUpdate,
	};
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(UPDATE_APPROVE_STATUS, paramData)
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default updateApproveStatusService;
