import { memo } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import { ICustomDropDown } from '../model';
import CustomError from '../errorLabel/CustomError';

/**
 * Custom dropdown component.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Function} props.handleDropDownChange - The change handler function.
 * @param {any} props.dropdownValue - The selected value.
 * @param {Array} props.options - The dropdown options.
 * @param {string} [props.className] - Optional className.
 * @param {string} [props.dataItemKey] - The data item key field.
 * @param {string} [props.dataTextField] - The data text field.
 * @param {boolean} [props.disabled] - Whether disabled.
 * @param {string} [props.label] - The label text.
 * @param {boolean} [props.isMandatory] - Whether required.
 * @param {string} [props.placeHolder] - The placeholder text.
 * @param {Object} [props.popupSettings] - Popup settings.
 * @param {any} [props.defaultValue] - Default selected value.
 * @param {boolean} [props.returnOtherItems] - Allow custom values.
 * @param {boolean} [props.isId] - Uses id value instead of text value.
 * @param {string} [props.error] - Error message.
 */
const CustomDropDown = ({
	handleDropDownChange,
	dropdownValue,
	options,
	className,
	dataItemKey,
	dataTextField,
	disabled,
	label,
	isMandatory,
	placeHolder,
	popupSettings,
	defaultValue,
	returnOtherItems,
	isId = false,
	error,
	pleaseSelectText,
	loading,
}: ICustomDropDown) => {
	/**
	 * Handle change event on dropdown.
	 *
	 * @param {Object} event - The change event object
	 */
	const onChange = (event: any) => {
		/**
		 * If returnOtherItems prop is true,
		 * call handleDropDownChange with event and other items
		 */
		if (returnOtherItems) {
			handleDropDownChange({ ...event, others: returnOtherItems });
		} else if (isId) {
			/**
			 * If isId prop is true,
			 * call handleDropDownChange with value of dataItemKey ie, just returning the id
			 */
			handleDropDownChange(event.value?.[dataItemKey]);
		} else {
			/**
			 * Otherwise just pass the value to handleDropDownChange
			 */
			handleDropDownChange(event.value);
		}
	};
	/**
	 * Get the selected value object from options
	 *
	 * @returns {Object} The selected value object
	 */
	const selectedValue = () => {
		const val = options?.filter((item: any) => {
			return item[dataItemKey] === dropdownValue;
		});
		/**
		 * If no matching value found, return default object
		 */
		if (val.length === 0) {
			return {
				[dataItemKey]: '',
				[dataTextField]: pleaseSelectText || 'Please select',
			};
		}
		/**
		 * Otherwise return the found object
		 */
		return val[0];
	};

	return (
		<>
			{label && <label className='form-label'>{label}</label>}
			{isMandatory && <span>*</span>}
			<div className={disabled ? 'kWrapper disabled' : 'kWrapper'}>
				<DropDownList
					disabled={disabled}
					popupSettings={popupSettings || {}}
					data={options}
					dataItemKey={dataItemKey}
					textField={dataTextField}
					// If isId true then selectedValue returns the object to be selected
					value={isId ? selectedValue() : dropdownValue}
					onChange={onChange}
					className={disabled ? `${className} disabled` : className || ''}
					label={placeHolder || ''}
					// if isId active then Please select will be the first item
					defaultItem={
						!isId
							? defaultValue
							: { [dataItemKey]: '', [dataTextField]: 'Please select' }
					}
					loading={loading}
				/>
			</div>
			{error && error !== '' && <CustomError value={error} />}
		</>
	);
};
export default memo(CustomDropDown);
