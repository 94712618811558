import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks/stateHooks';
import { ICON_DESCRIPTION_ID, Privileges, WEBLINK_DOWNLOAD_TYPE } from 'utils/enum';
import { getExpanded } from 'slices/productmoduleSlice';
import { getUserLoginData } from 'slices/userLoginDataSlice';

import CustomSwitch from 'components/common/button/CustomSwitch';
import CustomInput from 'components/common/forms/CustomInput';
import CustomTextArea from 'components/common/forms/CustomTextArea';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import CustomError from 'components/common/errorLabel/CustomError';
import CustomDropdown from 'components/common/forms/CustomDropdown';
import CustomButton from 'components/common/button/CustomButton';
import ProductGeneralSkeleton from 'components/common/skeleton/ProductGeneralSkeleton';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import CustomLoader from 'components/common/loader/CustomLoader';
import ShowIcon from 'components/common/fontAwesome/ShowIcon';
import ProductLoaderSkeleton from 'components/common/skeleton/ProductLoaderSkeleton';
import { hasPrivileges } from 'utils/utils';
import { ELEAFLET_SUPPORT_FILES } from 'utils/constants';
import GeneralFunctions from './general/GeneralFunctions';
import WebLinkDownload from './WebLinkDownload';

const General = () => {
	const { t } = useTranslation('productModule');
	const { t: tEndUserScan } = useTranslation('endUserScan');
	const expanded = useAppSelector(getExpanded);
	const getLoginUserData = useAppSelector(getUserLoginData);
	const {
		productName,
		editCommonAttributes,
		editGeneralAttributes,
		editIVedaAttributes,
		commonProductCode,
		productDescription,
		isEndUserScanEnabled,
		encManufacturerID,
		// encProductCategoryId,
		productGroup,
		generalLoading,
		errorState,
		generalErrorState,
		ProductGroupNameDetails,
		manufacturerDetailsData,
		// productCategoryData,
		productTypeData,
		// encproductType,
		dosageData,
		// encDosageIVeda,
		iVedaData,
		generalField,
		panelBarStatus,
		// productNameIVeda,
		// productCodeIVeda,
		mode,
		genericName,
		// genericNameIVeda,
		composition,
		// compositionIVeda,
		strength,
		// strengthIveda,
		gs1CompanyPrefix,
		// storageConditionIVeda,
		storageCondition,
		// otherDosage,
		// otherDosageError,
		encProductId,
		weblinkStatus,
		commonAttributesInfo,
		generalInfo,
		iVedaPropertitesInfo,
		eLeafletName,
		eLeafletError,
		eLeafletDownloadloading,
		eLeafletFileName,
		generalDetailsLoading,
		generalSaveLoading,
		iVedaPropertiesStatus,
		ivedaScheduleData,
		storageConditionData,
		// encScheduleId,
		// encStorageConditionId,
		ivedaErrorState,
		groupChangeLoader,
		eLeafletRef,

		isFieldActive,
		handleProductName,
		handleCommonProductCode,
		handleProductDescription,
		handleIsEndUserScanEnabled,
		handleManufacturerDropDownChange,
		// handleProductCategoryDropDownChange,
		handleProductGroupDropDownChange,
		handleIVedaProductCode,
		handleIVedaProductName,
		handleGenericNameGeneral,
		handleGenericNameIVeda,
		handleCompositionGeneral,
		handleCompositionIVeda,
		handleStrengthGeneral,
		handleStrengthIveda,
		handleStorageConditionGeneral,
		// handleStorageConditionIveda,
		handleGeneralInputChange,
		handleInputChange,
		handleProductTypeChange,
		handleDosageChange,
		// handleOtherDosageChange,
		handleIvadeEditClick,
		handleGenaralEditClick,
		handleCommonAttributeEditClick,
		handleSelect,
		handleSave,
		handleFileChange,
		handleDownload,
		isIVedaPropertiesFieldActive,
		handleScheduleChange,
		handleStorageConditionChange,
		handleELeafletFileClear,
		manufacturerLoading,
		productTypeLoading,
		ivedaScheduleLoading,
		storageConditionLoading,
		dosageLoading,
	} = GeneralFunctions();

	return (
		<>
			{generalLoading || generalDetailsLoading ? (
				<ProductGeneralSkeleton />
			) : (
				<div className='form-body position-relative'>
					<PanelBar
						isControlled
						expanded={expanded}
						expandMode='multiple'
						onSelect={handleSelect}>
						<PanelBarItem
							title={
								<div id='commonAttrHeading'>
									<h4 className='form-section-title mb-3 mt-3 info-title'>
										{t('COMMON_ATTRIBUTES')}
										<CustomEditableTooltip
											showIcon='faInfoCircle'
											text={commonAttributesInfo}
											moduleId={ICON_DESCRIPTION_ID.COMMON_ATTRIBUTES}
										/>
									</h4>
									{mode === 'edit' && (
										<CustomButton
											buttonClassName='edit-icon-btn'
											type='button'
											handleClick={handleCommonAttributeEditClick}
											icon='faEdit'
										/>
									)}
								</div>
							}>
							<div className='section-wrapper'>
								<div className='row'>
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomInput
												disabled={editCommonAttributes}
												className=''
												value={productName}
												label={t('PRODUCT_NAME')!!}
												onChange={(e: any) => handleProductName(e)}
												name='productName'
												placeholder={t('PRODUCT_NAME')}
												required
												type='text'
												error={errorState?.productName}
												isMandatory
											/>
										</div>
									</div>
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomInput
												disabled={editCommonAttributes}
												className=''
												value={commonProductCode}
												label={t('PRODUCT_CODE')!!}
												onChange={(e: any) => handleCommonProductCode(e)}
												name='commonProductCode'
												placeholder={t('PRODUCT_CODE')!!}
												required
												type='text'
												error={errorState?.commonProductCode}
												isMandatory
											/>
										</div>
									</div>

									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomTextArea
												disabled={editCommonAttributes}
												className=' two-row-height'
												value={productDescription}
												onChange={(e: any) => handleProductDescription(e)}
												label={t('PRODUCT_DESCRIPTION')!!}
												placeholder={t('PRODUCT_DESCRIPTION')!!}
												textAreaName='productDescription'
												textAreaId='productDescription'
												required
												cols={30}
												rows={5}
												error={errorState?.productDescription}
												isMandatory
											/>
										</div>
									</div>

									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomDropdown
												popupSettings={{
													popupClass: 'default-select-dropdown',
												}}
												isId
												disabled={editCommonAttributes}
												options={manufacturerDetailsData}
												dropdownValue={encManufacturerID}
												handleDropDownChange={(e: any) =>
													handleManufacturerDropDownChange(e)
												}
												className='form-control default-select-dropdown'
												label={t('MANUFACTURER')!!}
												isMandatory
												dataItemKey='encManufacturerId'
												dataTextField='manufacturerName'
												loading={manufacturerLoading}
											/>
											{errorState?.encManufacturerID &&
												errorState?.encManufacturerID !== '' && (
													<CustomError
														value={errorState?.encManufacturerID}
													/>
												)}
										</div>

										{/* <div className='form-group'>
											<CustomDropdown
												popupSettings={{
													popupClass: 'default-select-dropdown',
												}}
												isId
												disabled={editCommonAttributes}
												options={productCategoryData}
												dropdownValue={encProductCategoryId}
												handleDropDownChange={(e: any) =>
													handleProductCategoryDropDownChange(e)
												}
												className='form-control default-select-dropdown'
												label={t('PRODUCT_CATEGORY')!!}
												isMandatory
												dataItemKey='encProductCategoryId'
												dataTextField='productCategoryName'
											/>
											{errorState?.encProductCategoryId &&
												errorState?.encProductCategoryId !== '' && (
													<CustomError
														value={errorState?.encProductCategoryId}
													/>
												)}
										</div> */}
										<div className='form-group'>
											<CustomDropdown
												popupSettings={{
													popupClass: 'default-select-dropdown',
												}}
												isId
												disabled={editCommonAttributes}
												dropdownValue={productGroup}
												options={ProductGroupNameDetails}
												handleDropDownChange={(e: any) =>
													handleProductGroupDropDownChange(e)
												}
												className='form-control default-select-dropdown'
												label={t('PRODUCT_GROUP')!!}
												isMandatory
												dataItemKey='encProductgroupId'
												dataTextField='productGroup'
												loading={generalLoading}
											/>
											{errorState?.productGroup &&
												errorState?.productGroup !== '' && (
													<CustomError value={errorState?.productGroup} />
												)}
										</div>
									</div>

									{!panelBarStatus && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<label className='form-label'>
													{t('E_LEAFLET')!!}
												</label>

												{(mode === 'add' || mode === 'edit') && (
													<>
														<div
															className={
																editCommonAttributes
																	? 'default-upload-wrapper disabled'
																	: 'default-upload-wrapper '
															}>
															<div className='browse-outer'>
																<div className='browse-inner'>
																	<p>{t('DRAG_AND_DROP')!!}</p>
																	&nbsp;
																	<label className='browse-btn'>
																		<span>
																			{t('CHOOSE_FILE')!!}
																		</span>
																	</label>
																	&nbsp;
																	<p>{t('TO_UPLOAD')!!}</p>
																</div>
																<p>
																	{t('E_LEAFLET_MAX_FILE_SIZE')!!}{' '}
																	{t('SUPPORTED_FILES_FORMATS')!!}{' '}
																	{ELEAFLET_SUPPORT_FILES}
																</p>
															</div>
															<input
																ref={eLeafletRef}
																type='file'
																accept='.pdf'
																disabled={editCommonAttributes}
																onChange={(e) =>
																	handleFileChange(e)
																}
																alt=' '
																required
																id='file-upload-btn'
																className='dragdrop'
															/>
														</div>
														<div className='upload-leaf-wrapper my-2'>
															<span className='fileName-wrapper'>
																<span className='form-label leaf-label'>
																	{t('E_LEAFLET_FILE_NAME')}:
																</span>
																<span className='fileName'>
																	{eLeafletName || t('NO_FILE')}
																</span>
																{eLeafletName && (
																	<CustomButton
																		buttonClassName={
																			editCommonAttributes
																				? 'btn btn-icon ms-1 disabled'
																				: 'btn btn-icon ms-1'
																		}
																		disabled={
																			editCommonAttributes
																		}
																		type='button'
																		handleClick={
																			handleELeafletFileClear
																		}
																		icon='faClose'
																	/>
																)}
															</span>
														</div>
														{eLeafletError && eLeafletError !== '' && (
															<CustomError value={eLeafletError} />
														)}
													</>
												)}
												{eLeafletFileName ? (
													<span className='default-download-product-file my-2'>
														{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/jsx-no-comment-textnodes */}
														<span
															className='download-file-wrap'
															onClick={() => handleDownload()}>
															{eLeafletDownloadloading ? (
																<span className='loader-primary'>
																	<CustomLoader />
																</span>
															) : (
																<>
																	<span className='download-lbl-wrap'>
																		<span>
																			<ShowIcon name='faFilePdf' />
																		</span>
																		<span className='mfg-value-lbl'>
																			{tEndUserScan(
																				'DOWNLOAD_E_LEAFLET',
																			)}
																		</span>
																	</span>

																	<span>
																		<ShowIcon name='faAngleRight' />
																	</span>
																</>
															)}
														</span>
													</span>
												) : (
													(mode === 'view' ||
														mode === 'review' ||
														mode === 'approve') && (
														<div className='text-center my-2 no-data-text'>
															{t('NO_FILE_FOUND')!!}
														</div>
													)
												)}
											</div>
										</div>
									)}

									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<div
												className={`form-control-switch-btn me-0 d-flex justify-content-between align-items-center ${
													editCommonAttributes ? 'cursor-not-allowed' : ''
												}`}>
												<label className='form-label mb-0'>
													{t('IS_ENDUSER_SCAN_ENABLED')!!}
												</label>
												<CustomSwitch
													disabled={editCommonAttributes}
													checked={isEndUserScanEnabled}
													name='isEndUserScanEnabled'
													handleChange={handleIsEndUserScanEnabled}
													onLabel=''
													offLabel=''
												/>
											</div>
										</div>
									</div>
									{hasPrivileges(
										Privileges.DownloadWeblink,
										getLoginUserData,
									) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<WebLinkDownload
													show={weblinkStatus}
													productId={encProductId}
													weblinkDownloadType={
														WEBLINK_DOWNLOAD_TYPE.PRODUCT
													}
												/>
											</div>
										</div>
									)}
								</div>
							</div>
						</PanelBarItem>
						<PanelBarItem
							disabled={panelBarStatus}
							title={
								<div id='genaralHeading'>
									<h4 className='form-section-title mb-3 mt-3 info-title'>
										{t('GENERAL')}
										<CustomEditableTooltip
											showIcon='faInfoCircle'
											text={generalInfo}
											moduleId={ICON_DESCRIPTION_ID.GENERAL}
										/>
									</h4>
									{mode === 'edit' && (
										<CustomButton
											buttonClassName='edit-icon-btn'
											type='button'
											handleClick={handleGenaralEditClick}
											icon='faEdit'
										/>
									)}
								</div>
							}>
							{groupChangeLoader ? (
								<ProductLoaderSkeleton />
							) : (
								<div className='section-wrapper'>
									<div className='row'>
										{isFieldActive(1) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														value={gs1CompanyPrefix}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='gs1CompanyPrefix'
														label={t('GS1_COMPANY_PREFIX')!!}
														placeholder={t('GS1_COMPANY_PREFIX')!!}
														required
														type='text'
														isMandatory
														// error={generalErrorState?.gs1CompanyPrefix}
													/>
												</div>
											</div>
										)}
										{isFieldActive(2) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.productSecName}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='productSecName'
														label={t('PRODUCT_NAME')!!}
														placeholder={t('PRODUCT_NAME')!!}
														required
														type='text'
														error={generalErrorState?.productSecName}
														isMandatory
													/>
												</div>
											</div>
										)}

										{isFieldActive(3) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.unitPrice}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='unitPrice'
														label={t('UNIT_PRICE')!!}
														error={generalErrorState?.unitPrice}
														placeholder={t('UNIT_PRICE')!!}
														required
														type='text'
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(4) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={genericName}
														onChange={(e: any) =>
															handleGenericNameGeneral(e)
														}
														name='genericName'
														label={t('GENERIC_NAME')!!}
														placeholder={t('GENERIC_NAME')!!}
														required
														type='text'
														error={generalErrorState?.genericName}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(5) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.dosage}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='dosage'
														label={t('DOSAGE')!!}
														placeholder={t('DOSAGE')!!}
														required
														type='text'
														error={generalErrorState?.dosage}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(6) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={strength}
														onChange={(e: any) =>
															handleStrengthGeneral(e)
														}
														name='strength'
														label={t('STRENGTH')!!}
														placeholder={t('STRENGTH')!!}
														required
														type='text'
														error={generalErrorState?.strength}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(7) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.formType}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='formType'
														label={t('FORM_TYPE')!!}
														placeholder={t('FORM_TYPE')!!}
														required
														type='text'
														error={generalErrorState?.formType}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(8) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.packType}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='packType'
														label={t('PACK_TYPE')!!}
														placeholder={t('PACK_TYPE')!!}
														required
														type='text'
														error={generalErrorState?.packType}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(9) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={storageCondition}
														onChange={(e: any) =>
															handleStorageConditionGeneral(e)
														}
														name='storageCondition'
														label={t('STORAGE_CONDITION')!!}
														placeholder={t('STORAGE_CONDITION')!!}
														required
														type='text'
														error={generalErrorState?.storageCondition}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(10) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.revisionNumber}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='revisionNumber'
														label={t('REVISION_NUMBER')!!}
														placeholder={t('REVISION_NUMBER')!!}
														required
														type='text'
														error={generalErrorState?.revisionNumber}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(11) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.licenseNumber}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='licenseNumber'
														label={t('LICENSE_NUMBER')!!}
														placeholder={t('LICENSE_NUMBER')!!}
														required
														type='text'
														error={generalErrorState?.licenseNumber}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(12) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.weight}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='weight'
														label={t('WEIGHT')!!}
														placeholder={t('WEIGHT')!!}
														required
														type='text'
														error={generalErrorState?.weight}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(31) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.ai710GermanyIFA}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='ai710GermanyIFA'
														label={t('AI_710_GERMANY_IFA')!!}
														placeholder={t('AI_710_GERMANY_IFA')!!}
														required
														type='text'
														error={generalErrorState?.ai710GermanyIFA}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(32) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.ai711FranceCIP}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='ai711FranceCIP'
														label={t('AI_711_FRANCE_CIP')!!}
														placeholder={t('AI_711_FRANCE_CIP')!!}
														required
														type='text'
														error={generalErrorState?.ai711FranceCIP}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(33) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.ai712SpainNationalCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='ai712SpainNationalCode'
														label={t('AI_712_SPAIN_NATIONAL_CODE')!!}
														placeholder={
															t('AI_712_SPAIN_NATIONAL_CODE')!!
														}
														required
														type='text'
														error={
															generalErrorState?.ai712SpainNationalCode
														}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(34) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.atPZN}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='atPZN'
														label={t('AT_PZN')!!}
														placeholder={t('AT_PZN')!!}
														required
														type='text'
														error={generalErrorState?.atPZN}
														isMandatory
													/>
												</div>
											</div>
										)}

										{isFieldActive(35) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														value={generalField?.beABPCode}
														disabled={editGeneralAttributes}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='beABPCode'
														label={t('BE_ABP_CODE')!!}
														placeholder={t('BE_ABP_CODE')!!}
														required
														type='text'
														error={generalErrorState?.beABPCode}
														isMandatory
													/>
												</div>
											</div>
										)}

										{isFieldActive(36) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={
															generalField?.brAnvisaRegistrationNumber
														}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='brAnvisaRegistrationNumber'
														label={t('BR_ANVISA_REGISTRATION_NUMBER')!!}
														placeholder={
															t('BR_ANVISA_REGISTRATION_NUMBER')!!
														}
														required
														type='text'
														error={
															generalErrorState?.brAnvisaRegistrationNumber
														}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(37) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.caDIN}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='caDIN'
														label={t('CA_DIN')!!}
														placeholder={t('CA_DIN')!!}
														required
														type='text'
														error={generalErrorState?.caDIN}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(38) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.chSwissmedic}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='chSwissmedic'
														label={t('CH_SWISSMEDIC')!!}
														placeholder={t('CH_SWISSMEDIC')!!}
														required
														type='text'
														error={generalErrorState?.chSwissmedic}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(39) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.cnSubtypeCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='cnSubtypeCode'
														label={t('CN_SUBTYPE_CODE')!!}
														placeholder={t('CN_SUBTYPE_CODE')!!}
														required
														type='text'
														error={generalErrorState?.cnSubtypeCode}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(40) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.dePPN}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='dePPN'
														label={t('DE_PPN')!!}
														placeholder={t('DE_PPN')!!}
														required
														type='text'
														error={generalErrorState?.dePPN}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(41) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.dePZN}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='dePZN'
														label={t('DE_PZN')!!}
														placeholder={t('DE_PZN')!!}
														required
														type='text'
														error={generalErrorState?.dePZN}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(14) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.ean13}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='ean13'
														label={t('EAN_13')!!}
														placeholder={t('EAN_13')!!}
														required
														type='text'
														error={generalErrorState?.ean13}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(42) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.grEOFCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='grEOFCode'
														label={t('GR_EOF_CODE')!!}
														placeholder={t('GR_EOF_CODE')!!}
														required
														type='text'
														error={generalErrorState?.grEOFCode}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(43) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.hrCroatiaNationalCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='hrCroatiaNationalCode'
														label={t('HR_CROATIA_NATIONAL_CODE')!!}
														placeholder={
															t('HR_CROATIA_NATIONAL_CODE')!!
														}
														required
														type='text'
														error={
															generalErrorState?.hrCroatiaNationalCode
														}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(44) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.inProductCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='inProductCode'
														label={t('IN_PRODUCT_CODE')!!}
														placeholder={t('IN_PRODUCT_CODE')!!}
														required
														type='text'
														error={generalErrorState?.inProductCode}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(15) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.internalMaterialNumber}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='internalMaterialNumber'
														label={t('INTERNAL_MATERIAL_NUMBER')!!}
														placeholder={
															t('INTERNAL_MATERIAL_NUMBER')!!
														}
														required
														type='text'
														error={
															generalErrorState?.internalMaterialNumber
														}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(45) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.itBollino}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='itBollino'
														label={t('IT_BOLINO')!!}
														placeholder={t('IT_BOLINO')!!}
														required
														type='text'
														error={generalErrorState?.itBollino}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(46) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.krKFDACode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='krKFDACode'
														label={t('KR_KFDA_CODE')!!}
														placeholder={t('KR_KFDA_CODE')!!}
														required
														type='text'
														error={generalErrorState?.krKFDACode}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(47) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.nlKNMP}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='nlKNMP'
														label={t('NL_KNMP')!!}
														placeholder={t('NL_KNMP')!!}
														required
														type='text'
														error={generalErrorState?.nlKNMP}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(48) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.nrdNordicVNRDrugCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='nrdNordicVNRDrugCode'
														label={t('NRD_NORDIC_VNR_DRUG_CODE')!!}
														placeholder={
															t('NRD_NORDIC_VNR_DRUG_CODE')!!
														}
														required
														type='text'
														error={
															generalErrorState?.nrdNordicVNRDrugCode
														}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(49) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.ptAIMNumber}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='ptAIMNumber'
														label={t('PT_AIM_NUMBER')!!}
														placeholder={t('PT_AIM_NUMBER')!!}
														required
														type='text'
														error={generalErrorState?.ptAIMNumber}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(50) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.saSaudiDrugCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='saSaudiDrugCode'
														label={t('SA_SAUDI_DRUG_CODE')!!}
														placeholder={t('SA_SAUDI_DRUG_CODE')!!}
														required
														type='text'
														error={generalErrorState?.saSaudiDrugCode}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(51) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.usNDC442}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='usNDC442'
														label={t('US_NDC442')!!}
														placeholder={t('US_NDC442')!!}
														required
														type='text'
														error={generalErrorState?.usNDC442}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(52) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.usNDC532}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='usNDC532'
														label={t('US_NDC532')!!}
														placeholder={t('US_NDC532')!!}
														required
														type='text'
														error={generalErrorState?.usNDC532}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(53) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.usNDC541}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='usNDC541'
														label={t('US_NDC541')!!}
														placeholder={t('US_NDC541')!!}
														required
														type='text'
														error={generalErrorState?.usNDC541}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(54) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.usNDC542}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='usNDC542'
														label={t('US_NDC542')!!}
														placeholder={t('US_NDC542')!!}
														required
														type='text'
														error={generalErrorState?.usNDC542}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(55) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.ai714PortugalAIM}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='ai714PortugalAIM'
														label={t('AI_714_PORTUGAL_AIM')!!}
														placeholder={t('AI_714_PORTUGAL_AIM')!!}
														required
														type='text'
														error={generalErrorState?.ai714PortugalAIM}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(16) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<div className='form-control-switch-btn me-0 d-flex justify-content-between align-items-center'>
														<label className='form-label mb-0'>
															{
																t(
																	'AI_27_GOVERNMENT_PRODUCT_NOT_FOR_SALE',
																)!!
															}
														</label>
														<CustomSwitch
															disabled={editGeneralAttributes}
															checked={
																generalField?.ai27ProductNotforSale
															}
															className='ms-2'
															name='ai27ProductNotforSale'
															handleChange={handleGeneralInputChange}
															onLabel=''
															offLabel=''
														/>
													</div>
												</div>
											</div>
										)}
										{isFieldActive(17) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.tNVED4}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='tNVED4'
														label={t('TNVED_4')!!}
														placeholder={t('TNVED_4')!!}
														required
														type='text'
														error={generalErrorState?.tNVED4}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(56) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.vat}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='vat'
														label={t('VAT')!!}
														placeholder={t('VAT')!!}
														required
														type='text'
														error={generalErrorState?.vat}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(57) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.siNTINCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='siNTINCode'
														label={t('SI_NTIN_CODE')!!}
														placeholder={t('SI_NTIN_CODE')!!}
														required
														type='text'
														error={generalErrorState?.siNTINCode}
														isMandatory
													/>
												</div>
											</div>
										)}

										{isFieldActive(58) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.fdaNDC11}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='fdaNDC11'
														label={t('FDA_NDC_11')!!}
														placeholder={t('FDA_NDC_11')!!}
														required
														type='text'
														error={generalErrorState?.fdaNDC11}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(59) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.article57Code}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='article57Code'
														label={t('ARTICLE_57_CODE')!!}
														placeholder={t('ARTICLE_57_CODE')!!}
														required
														type='text'
														error={generalErrorState?.article57Code}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(60) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														value={generalField?.treacebilityCodeName}
														disabled={editGeneralAttributes}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='treacebilityCodeName'
														label={t('TREACEABILITY_CODE_NAME')!!}
														placeholder={t('TREACEABILITY_CODE_NAME')!!}
														required
														type='text'
														error={
															generalErrorState?.treacebilityCodeName
														}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(19) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.barcode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='barcode'
														label={t('BARCODE')!!}
														placeholder={t('BARCODE')!!}
														required
														type='text'
														error={generalErrorState?.barcode}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(20) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.digitalCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='digitalCode'
														label={t('DIGITAL_CODE')!!}
														placeholder={t('DIGITAL_CODE')!!}
														required
														type='text'
														error={generalErrorState?.digitalCode}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(21) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-control-switch-btn me-0 d-flex justify-content-between align-items-center'>
													<label className='form-label mb-0'>
														{t('SCAN_CODE_PROMPT')!!}
													</label>
													<CustomSwitch
														disabled={editGeneralAttributes}
														checked={generalField?.scanCodePrompt}
														name='scanCodePrompt'
														handleChange={handleGeneralInputChange}
														className='ms-2'
														onLabel=''
														offLabel=''
													/>
												</div>
											</div>
										)}
										{isFieldActive(22) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.callEnquiries}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='callEnquiries'
														label={t('CALL_ENQUIRIES')!!}
														placeholder={t('CALL_ENQUIRIES')!!}
														required
														type='text'
														error={generalErrorState?.callEnquiries}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(23) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={composition}
														onChange={(e: any) =>
															handleCompositionGeneral(e)
														}
														name='composition'
														label={t('COMPOSITION')!!}
														placeholder={t('COMPOSITION')!!}
														required
														type='text'
														error={generalErrorState?.composition}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(61) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.ukNHRN}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='ukNHRN'
														label={t('UK_NHRN')!!}
														placeholder={t('UK_NHRN')!!}
														required
														type='text'
														error={generalErrorState?.ukNHRN}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(62) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.cnProductCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='cnProductCode'
														label={t('CN_PRODUCT_CODE')!!}
														placeholder={t('CN_PRODUCT_CODE')!!}
														required
														type='text'
														error={generalErrorState?.cnProductCode}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(63) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.ai713BrazilAnvisa}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='ai713BrazilAnvisa'
														label={t('AI_713_BRAZIL_ANVISA')!!}
														placeholder={t('AI_713_BRAZIL_ANVISA')!!}
														required
														type='text'
														error={generalErrorState?.ai713BrazilAnvisa}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(64) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.cnPackagespec}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='cnPackagespec'
														label={t('CN_PACKAGESPEC')!!}
														placeholder={t('CN_PACKAGESPEC')!!}
														required
														type='text'
														error={generalErrorState?.cnPackagespec}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(24) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.grossWeight}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='grossWeight'
														label={t('GROSS_WEIGHT')!!}
														placeholder={t('GROSS_WEIGHT')!!}
														required
														type='text'
														error={generalErrorState?.grossWeight}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(25) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.netWeight}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='netWeight'
														label={t('NET_WEIGHT')!!}
														placeholder={t('NET_WEIGHT')!!}
														required
														type='text'
														error={generalErrorState?.netWeight}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(26) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.unitProductDimension}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='unitProductDimension'
														label={t('UNIT_PRODUCT_DIMENSIONS')!!}
														placeholder={t('UNIT_PRODUCT_DIMENSIONS')!!}
														required
														type='text'
														error={
															generalErrorState?.unitProductDimension
														}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(65) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.usNDCPharma}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='usNDCPharma'
														label={t('US_NDC_PHARMA')!!}
														placeholder={t('US_NDC_PHARMA')!!}
														required
														type='text'
														error={generalErrorState?.usNDCPharma}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(88) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.brandName}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='brandName'
														label={t('BRAND_NAME')!!}
														placeholder={t('BRAND_NAME')!!}
														required
														type='text'
														error={generalErrorState?.brandName}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(27) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.shelfLifeInDays}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='shelfLifeInDays'
														label={t('SHELF_LIFE_IN_DAYS')!!}
														placeholder={t('SHELF_LIFE_IN_DAYS')!!}
														required
														type='text'
														error={generalErrorState?.shelfLifeInDays}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(28) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.batchSize}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='batchSize'
														label={t('BATCH_SIZE')!!}
														placeholder={t('BATCH_SIZE')!!}
														required
														type='text'
														error={generalErrorState?.batchSize}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(29) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.fgCode}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='fgCode'
														label={t('FG_CODE')!!}
														placeholder={t('FG_CODE')!!}
														required
														type='text'
														error={generalErrorState?.fgCode}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(30) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editGeneralAttributes}
														value={generalField?.marketRegion}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														name='marketRegion'
														label={t('MARKET_REGION')!!}
														placeholder={t('MARKET_REGION')!!}
														required
														type='text'
														error={generalErrorState?.marketRegion}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(13) && (
											<div className='col-xl-12'>
												<div className='form-group'>
													<CustomTextArea
														disabled={editGeneralAttributes}
														value={
															generalField?.ai240AdditionalItemInfo
														}
														textAreaName='ai240AdditionalItemInfo'
														label={t('AI_240_ADDITIONAL_ITEM_INFO')!!}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														className='two-row-height'
														placeholder={
															t('AI_240_ADDITIONAL_ITEM_INFO')!!
														}
														textAreaId='ai240AdditionalItemInfo'
														required
														cols={30}
														rows={5}
														error={
															generalErrorState?.ai240AdditionalItemInfo
														}
														isMandatory
													/>
												</div>
											</div>
										)}
										{isFieldActive(18) && (
											<div className='col-xl-12'>
												<div className='form-group'>
													<CustomTextArea
														disabled={editGeneralAttributes}
														value={generalField?.netContentDescription}
														textAreaName='netContentDescription'
														label={t('NET_CONTENT_DESCRIPTION')!!}
														onChange={(e: any) =>
															handleGeneralInputChange(e)
														}
														placeholder={t('NET_CONTENT_DESCRIPTION')!!}
														className='two-row-height'
														textAreaId='netContentDescription'
														required
														cols={30}
														rows={5}
														error={
															generalErrorState?.netContentDescription
														}
														isMandatory
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							)}
						</PanelBarItem>
						{/**
						 * i veda properties
						 * fields
						 *  */}
						{iVedaPropertiesStatus && (
							<PanelBarItem
								disabled={panelBarStatus}
								title={
									<div id='ivedaHeading'>
										<h4 className='form-section-title mb-3 mt-3 info-title'>
											{t('I_VEDA_PROPERTIES')}
											<CustomEditableTooltip
												showIcon='faInfoCircle'
												text={iVedaPropertitesInfo}
												moduleId={ICON_DESCRIPTION_ID.I_VEDA_PROPERTIES}
											/>
										</h4>
										{mode === 'edit' && (
											<CustomButton
												buttonClassName='edit-icon-btn'
												type='button'
												handleClick={handleIvadeEditClick}
												icon='faEdit'
											/>
										)}
									</div>
								}>
								{groupChangeLoader ? (
									<ProductLoaderSkeleton />
								) : (
									<div className='section-wrapper'>
										<div className='row'>
											{isIVedaPropertiesFieldActive(87) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.productNameIVeda}
															onChange={(e: any) =>
																handleIVedaProductName(e)
															}
															label={t('PRODUCT_NAME')!!}
															name='productNameIVeda'
															placeholder={t('PRODUCT_NAME')}
															required
															type='text'
															error={
																ivedaErrorState?.productNameIVeda
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(66) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.productCodeIVeda}
															onChange={(e: any) =>
																handleIVedaProductCode(e)
															}
															label={t('PRODUCT_CODE')!!}
															name='productCodeIVeda'
															placeholder={t('PRODUCT_CODE')!!}
															required
															type='text'
															error={
																ivedaErrorState?.productCodeIVeda
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(67) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.hsCode}
															onChange={handleInputChange}
															label={t('HS_CODE')!!}
															name='hsCode'
															placeholder={t('HS_CODE')!!}
															required
															type='text'
															error={ivedaErrorState?.hsCode}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(68) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomDropdown
															popupSettings={{
																popupClass:
																	'default-select-dropdown',
															}}
															disabled={editIVedaAttributes}
															isId
															className='form-control default-select-dropdown'
															dropdownValue={
																iVedaData?.encproductType
															}
															options={productTypeData}
															label={t('PRODUCT_TYPE')!!}
															handleDropDownChange={
																handleProductTypeChange
															}
															dataItemKey='encproductType'
															dataTextField='productType'
															loading={productTypeLoading}
															// isMandatory
														/>
														{ivedaErrorState?.encproductType &&
															ivedaErrorState?.encproductType !==
																'' && (
																<CustomError
																	value={
																		ivedaErrorState?.encproductType
																	}
																/>
															)}
													</div>
												</div>
											)}
											{/* change value */}
											{isIVedaPropertiesFieldActive(89) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.manufacturerCode}
															onChange={handleInputChange}
															label={t('MANUFACTURER_CODE')!!}
															name='manufacturerCode'
															placeholder={t('MANUFACTURER_CODE')!!}
															required
															type='text'
															error={
																ivedaErrorState?.manufacturerCode
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(71) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.manufacturingSiteCode}
															onChange={handleInputChange}
															label={t('MANUFACTURING_SITE_CODE')!!}
															name='manufacturingSiteCode'
															placeholder={
																t('MANUFACTURING_SITE_CODE')!!
															}
															required
															type='text'
															error={
																ivedaErrorState?.manufacturingSiteCode
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(70) && (
												// <div className='col-xl-6 col-lg-6'>
												// 	<div className='form-group'>
												// 		<div className='form-control-switch-btn me-0 d-flex justify-content-between align-items-center'>
												// 			<label className='form-label mb-0'>
												// 				{t('SCHEDULED')!!}
												// 			</label>
												// 			<CustomSwitch
												// 				disabled={editIVedaAttributes}
												// 				checked={iVedaData?.scheduled}
												// 				name='scheduled'
												// 				handleChange={handleInputChange}
												// 				className='ms-2'
												// 				onLabel=''
												// 				offLabel=''
												// 			/>
												// 		</div>
												// 	</div>
												// </div>
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomDropdown
															popupSettings={{
																popupClass:
																	'default-select-dropdown',
															}}
															disabled={editIVedaAttributes}
															isId
															className='form-control default-select-dropdown'
															dropdownValue={iVedaData?.encScheduleId}
															options={ivedaScheduleData}
															label={t('SCHEDULED')!!}
															handleDropDownChange={
																handleScheduleChange
															}
															dataItemKey='encScheduleId'
															dataTextField='Schedule'
															loading={ivedaScheduleLoading}
															// isMandatory
														/>
														{ivedaErrorState?.encScheduleId &&
															ivedaErrorState?.encScheduleId !==
																'' && (
																<CustomError
																	value={
																		ivedaErrorState?.encScheduleId
																	}
																/>
															)}
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(72) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.genericNameIVeda}
															onChange={(e: any) =>
																handleGenericNameIVeda(e)
															}
															label={t('GENERIC_NAME')!!}
															name='genericNameIVeda'
															placeholder={t('GENERIC_NAME')!!}
															required
															type='text'
															error={
																ivedaErrorState?.genericNameIVeda
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(73) && (
												// <div className='col-xl-6 col-lg-6'>
												// 	<div className='form-group'>
												// 		<CustomInput
												// 			disabled={editIVedaAttributes}
												// 			className=''
												// 			value={storageConditionIVeda}
												// 			onChange={(e: any) =>
												// 				handleStorageConditionIveda(e)
												// 			}
												// 			label={t('STORAGE_CONDITION')!!}
												// 			name='storageConditionIVeda'
												// 			placeholder={t('STORAGE_CONDITION')!!}
												// 			required
												// 			type='text'
												// 			error={errorState?.storageConditionIVeda}
												// 			isMandatory
												// 		/>
												// 	</div>
												// </div>
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomDropdown
															popupSettings={{
																popupClass:
																	'default-select-dropdown',
															}}
															disabled={editIVedaAttributes}
															isId
															className='form-control default-select-dropdown'
															dropdownValue={
																iVedaData?.encStorageConditionId
															}
															options={storageConditionData}
															label={t('STORAGE_CONDITION')!!}
															handleDropDownChange={
																handleStorageConditionChange
															}
															dataItemKey='encStorageConditionId'
															dataTextField='storageCondition'
															loading={storageConditionLoading}
															// isMandatory
														/>
														{ivedaErrorState?.encStorageConditionId &&
															ivedaErrorState?.encStorageConditionId !==
																'' && (
																<CustomError
																	value={
																		ivedaErrorState?.encStorageConditionId
																	}
																/>
															)}
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(74) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.primaryLevelGtin}
															onChange={handleInputChange}
															label={t('PRIMARY_LEVEL_GTIN')!!}
															name='primaryLevelGtin'
															placeholder={t('PRIMARY_LEVEL_GTIN')!!}
															required
															type='text'
															error={
																ivedaErrorState?.primaryLevelGtin
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(75) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.secondaryLevelGtin1}
															onChange={handleInputChange}
															label={t('SECONDARY_LEVEL_GTIN_1')!!}
															name='secondaryLevelGtin1'
															placeholder={
																t('SECONDARY_LEVEL_GTIN_1')!!
															}
															required
															type='text'
															error={
																ivedaErrorState?.secondaryLevelGtin1
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(76) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.secondaryLevelGtin2}
															onChange={handleInputChange}
															label={t('SECONDARY_LEVEL_GTIN_2')!!}
															name='secondaryLevelGtin2'
															placeholder={
																t('SECONDARY_LEVEL_GTIN_2')!!
															}
															required
															type='text'
															error={
																ivedaErrorState?.secondaryLevelGtin2
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(77) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.secondaryLevelGtin3}
															onChange={handleInputChange}
															label={t('SECONDARY_LEVEL_GTIN_3')!!}
															name='secondaryLevelGtin3'
															placeholder={
																t('SECONDARY_LEVEL_GTIN_3')!!
															}
															required
															type='text'
															error={
																ivedaErrorState?.secondaryLevelGtin3
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(78) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.tertiaryLevelGtin}
															onChange={handleInputChange}
															label={t('TERTIARY_LEVEL_GTIN')!!}
															name='tertiaryLevelGtin'
															placeholder={t('TERTIARY_LEVEL_GTIN')!!}
															required
															type='text'
															error={
																ivedaErrorState?.tertiaryLevelGtin
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(80) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomInput
															disabled={editIVedaAttributes}
															className=''
															value={iVedaData?.strengthIveda}
															onChange={(e: any) =>
																handleStrengthIveda(e)
															}
															label={t('STRENGTH')!!}
															name='strengthIveda'
															placeholder={t('STRENGTH')!!}
															required
															type='text'
															error={ivedaErrorState?.strengthIveda}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(81) && (
												<div className='col-xl-6 col-lg-6'>
													<div className='form-group'>
														<CustomDropdown
															popupSettings={{
																popupClass:
																	'default-select-dropdown',
															}}
															disabled={editIVedaAttributes}
															isId
															options={dosageData}
															dropdownValue={
																iVedaData?.encDosageIVeda
															}
															handleDropDownChange={
																handleDosageChange
															}
															className='form-control default-select-dropdown'
															label={t('DOSAGE')!!}
															dataItemKey='dosageId'
															dataTextField='dosage'
															loading={dosageLoading}
														/>
														{ivedaErrorState?.encDosageIVeda &&
															ivedaErrorState?.encDosageIVeda !==
																'' && (
																<CustomError
																	value={
																		ivedaErrorState?.encDosageIVeda
																	}
																/>
															)}
													</div>
												</div>
											)}
											{/* {encDosageIVeda === OTHER_DOSAGE_ID && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editIVedaAttributes}
														className=''
														value={otherDosage}
														onChange={(e: any) =>
															handleOtherDosageChange(e)
														}
														label={t('OTHER_DOSAGE')!!}
														name='otherDosage'
														placeholder={t('OTHER_DOSAGE')!!}
														required
														type='text'
														error={otherDosageError?.otherDosage}
														isMandatory
													/>
												</div>
											</div>
										)} */}
											{isIVedaPropertiesFieldActive(79) && (
												<div className='col-xl-12'>
													<div className='form-group'>
														<CustomTextArea
															disabled={editIVedaAttributes}
															className=' two-row-height'
															value={iVedaData?.compositionIVeda}
															onChange={(e: any) =>
																handleCompositionIVeda(e)
															}
															label={t('COMPOSITION')!!}
															placeholder={t('COMPOSITION')!!}
															textAreaName='compositionIVeda'
															textAreaId='compositionIVeda'
															required
															cols={30}
															rows={5}
															error={
																ivedaErrorState?.compositionIVeda
															}
															// isMandatory
														/>
													</div>
												</div>
											)}
											{isIVedaPropertiesFieldActive(69) && (
												<div className='col-xl-12'>
													<div className='form-group'>
														<CustomTextArea
															disabled={editIVedaAttributes}
															className=' two-row-height'
															value={iVedaData?.remark}
															onChange={handleInputChange}
															label={t('REMARK')!!}
															placeholder={t('REMARK')!!}
															textAreaName='remark'
															textAreaId='remark'
															required
															cols={30}
															rows={5}
															error={ivedaErrorState?.remark}
															// isMandatory
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								)}
							</PanelBarItem>
						)}
					</PanelBar>
				</div>
			)}

			{(mode === 'add' || mode === 'edit') && (
				<div className='bottom-btn-wrapper'>
					{!generalLoading && (
						<button
							type='button'
							disabled={generalSaveLoading || groupChangeLoader}
							className='btn btn-outline-primary btn-sm'
							onClick={handleSave}>
							{/* eslint-disable-next-line no-nested-ternary */}
							{generalSaveLoading ? (
								<span className='loader-primary'>
									<CustomLoader />
								</span>
							) : mode === 'add' ? (
								t('CREATE')
							) : (
								t('UPDATE')
							)}
						</button>
					)}
				</div>
			)}
		</>
	);
};

export default General;
