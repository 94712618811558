import { axiosPrivateInstance } from 'libs/axios';
import { useEffect, useState } from 'react';
import { GET_ALL_LOCATION_BY_ID } from 'utils/serviceUrls';

const useGetLocationById = (locationById: any) => {
	const [locationLoading, setLocationLoading] = useState<boolean>(false);
	const [resData, setResData] = useState<any>();

	useEffect(() => {
		if (locationById) {
			setLocationLoading(true);
			axiosPrivateInstance
				.post(GET_ALL_LOCATION_BY_ID, { encLocationId: locationById })
				.then((response: any) => {
					setResData(response.output);
					setLocationLoading(false);
				})
				.catch((error: any) => {
					console.log(error);
					setLocationLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationById]);

	return {
		locationLoading,
		resData,
	};
};

export default useGetLocationById;
