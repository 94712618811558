export const GET_ROLES_URL = 'api/v1/RoleManagement/GetAllRoles';
export const UPDATE_ROLES_URL = 'api/v1/RoleManagement/updateRole';
export const GET_TENANTS_URL = 'api/v1/Tenant/getAllTenantData';
export const ADD_TENANTS_URL = 'api/v1/Tenant/AddTenant';
export const GET_ALL_COUNTRY = 'api/v1/Master/GetAllCountry';
export const ADD_ROLE_URL = 'api/v1/RoleManagement/AddRole';

export const GET_ALL_PRIVILEGES_URL = 'api/v1/RoleManagement/GetAllPrivileges';
export const UPDATE_ROLE_STATUS = 'api/v1/RoleManagement/updateRoleStatus';
export const UPDATE_TENANT_URL = 'api/v1/Tenant/UpdateTenant';
export const UPDATE_USER_STATUS = 'api/v1/User/updateUserStatus';

export const GET_ALL_AREA_URL = 'api/v1/Master/GetAllArea';

export const FORGOT_PASSWORD_URL = 'api/v1/Authorize/ResetPassword';

export const LOGIN_URL = 'api/v1/Authorize/Login';
export const REFRESH_TOKEN = 'api/v1/Authorize/RefreshToken';
export const GET_USER_DETAILS_URL = 'api/v1/User/GetUserDetails';
export const RESET_PASSWORD_URL = 'api/v1/Authorize/ChangePassword';
export const TENANT_UPDATE_STATUS = 'api/v1/Tenant/UpdateTenantStatus';
export const GET_ALL_ACTIVE_ROLES_URL = 'api/v1/RoleManagement/GetAllActiveRole';
export const ADD_USER_URL = 'api/v1/User/AddUser';
export const UPDATE_USER_URL = 'api/v1/User/UpdateUserData';
export const GET_ALL_USERS = 'api/v1/User/GetAllUserDetails';
export const SEARCH_TENANT = 'api/v1/Tenant/SearchTenant';
export const VALIDATE_USER_TOKEN = 'api/v1/Authorize/ValidateUserToken';

export const GET_ALL_GLOBAL_CONFIG = 'api/v1/User/GetAllGlobalConfigurations';

export const REDIRECT_TO_TENANT = 'api/v1/User/RedirectUser';
export const UPDATE_SETTINGS_API = 'api/v1/User/UpdateGlobalConfigurations';
export const GET_ALL_DATE_FORMATS = 'api/v1/Master/GetAllDateFormats';
export const GET_ALL_END_USER_DATE_FORMATS = 'api/v1/Master/GetAllEndUserDateFormats';
export const AUTHENTICATE = 'api/v1/User/Authenticate';
export const PASSWORD_VERIFICATION_URL = 'api/v1/Authorize/passwordVerification';

export const GET_ALL_PRODUCT_GROUP = 'api/v1/Product/GetAllProductGroup';
export const GET_UPDATE_PGROUP_STATUS = 'api/v1/Product/UpdateProductGroupStatus';
export const ADD_PRODUCT_GROUP_URL = 'api/v1/Product/AddProductGroup';
export const GET_ALL_GENERAL_FIELD = 'api/v1/Product/GetAllProductGroupGeneralField';
export const GET_ALL_HRF_DROPDOWN_DATA = 'api/v1/Product/GetAllHrfDropDownDataByProductId';
export const GET_ALL_HRF = 'api/v1/Product/GetProductHrfDetailsByID';
export const UPDATE_PRODUCT_GROUP = 'api/v1/Product/UpdateProductGroup';
export const GET_ALL_PRODUCTS = 'api/v1/Product/GetAllProductDetails';
export const GET_ALL_MANUFACTURERS = 'api/v1/Product/GetAllManufacturerDetails';
export const UPDATE_MANUFACTURER_URL = 'api/v1/Product/UpdateManufacturerDetails';
export const GET_ALL_PRODUCT_CATEGORY = 'api/v1/Product/GetProductCategory';
export const ADD_MANUFACTURER_URL = 'api/v1/Product/AddManufacturerDetails';
export const MANUFACTURER_STATUS_UPDATE = 'api/v1/Product/UpdateManufacturerStatus';

export const GET_ALL_PRODUCT_GROUP_NAME = 'api/v1/Product/GetAllProductGroupName';
export const GET_ALL_PRODUCT_GROUP_BY_PRODUCT_GROUP_ID =
	'api/v1/Product/GetAllProductGroupByProductGroupId';
export const GET_ALL_PRODUCT_GROUP_BY_ID = 'api/v1/Product/GetAllProductGroupByProductGroupId';
export const GET_ALL_PACKAGE_LEVELS = 'api/v1/Product/GetAllPackageLevels';
export const ADD_PACKAGE_LEVEL = 'api/v1/Product/AddPackageLevel';
export const UPDATE_PACKAGE_LEVEL = 'api/v1/Product/UpdatePackageLevel';
export const UPDATE_PRODUCT_STATUS = 'api/v1/Product/UpdateProductStatus';
export const GET_ALL_PRODUCT_TYPE = 'api/v1/Product/GetAllProductType';

export const GET_ALL_CODE_TYPE = 'api/v1/Master/GetAllCodeType';
export const ADD_PRODUCT_PACKING = `api/v1/Product/AddProductPacking`;
export const GET_ALL_PRODUCT_PACK_LEVEL = 'api/v1/Master/GetAllProductPackLevel';
export const GET_ALL_PRODUCT_PACK_LEVEL_DATA_BY_ID = 'api/v1/Product/GetAllPrnDataByProductId';
export const SAVE_PRN_URL = 'api/v1/Product/SavePRNDetails';
export const ADD_HRF = 'api/v1/Product/AddHrf';

export const GET_ALL_DOSAGE = 'api/v1/Product/GetAllDosage';
export const ADD_PRODUCT_DETAILS = 'api/v1/Product/SaveProductDetails';
export const GET_ALL_PRODUCT_ATTRIBUTES_BY_GROUP_ID =
	'api/v1/Product/GetAllProductAttributesByGroupId';
export const GET_ALL_PRODUCT_PACKAGE_BY_ID = `api/v1/Product/GetProductPackingById`;
export const DELETE_PRODUCT_PACKING = 'api/v1/Product/DeleteProductPackingByID';
export const UPDATE_PRODUCT_PACKING = `api/v1/Product/UpdateProductPackingById`;
export const GET_PRODUCT_GENERAL_DETAILS_BY_ID = 'api/v1/Product/GetProductGeneralDetailsByID';
export const UPDATE_REVIEW_STATUS = 'api/v1/Product/UpdateProductReviewStatus';
export const UPDATE_APPROVE_STATUS = 'api/v1/Product/UpdateProductApproveStatus';
export const UPDATE_RETURNED_STATUS = 'api/v1/Product/UpdateProductReturnedStatus';
export const UPDATE_FINISH_STATUS = 'api/v1/Product/SubmitProductDetails';
export const GET_ALL_L3_SYSTEM = 'api/v1/L3Management/GetAllL3Systems';
export const GET_ALL_L3_SYSTEM_BY_ID = 'api/v1/L3Management/GetL3SystemById';
export const UPDATE_L3_SYSTEM = 'api/v1/L3Management/UpdateL3System';
export const GET_ALL_CONNECTIONS = 'api/v1/Master/GetAllConnectionMethods';
export const ADD_L3_SYSTEM = 'api/v1/L3Management/AddL3System';
export const UPDATE_L3_STATUS = 'api/v1/L3Management/UpdateL3SystemStatus';

export const GET_ALL_REGULATION = 'api/v1/Product/GetAllRegulation';
export const CHECK_WEBLINK_VALIDATION = 'api/v1/L3Management/CheckWebLinkValidation';
export const GET_WEBLINK_DOWNLOAD_FILE = 'api/v1/Product/GetWebLinkDownloadFile';
export const GET_ALL_SN_PROVIDER = 'api/v1/Product/GetAllSNProvider';
export const GET_ALL_PRODUCT_NAME = 'api/v1/Product/GetAllProductsDataBySearchText';
export const GET_ALL_BATCH_PO = 'api/v1/Product/GetAllProductOrderDetails';
export const UPDATE_PRODUCT_ORDER_RETRUNED_STATUS =
	'api/v1/Product/UpdateProductOrderReturnedStatus';
export const UPDATE_PRODUCT_ORDER_APPROVE_STATUS = 'api/v1/Product/UpdateProductOrderApproveStatus';
export const UPDATE_PRODUCT_ORDER_REVIEW_STATUS = 'api/v1/Product/UpdateProductOrderReviewStatus';
export const UPDATE_PO = 'api/v1/Product/EditProductOrderDetails';
export const ADD_PO = 'api/v1/Product/AddProductOrderDetails';
export const UPDATE_PRODUCT_ORDER_STATUS = 'api/v1/Product/UpdateProductOrderStatus';
export const GET_PO_DETAILS_BY_ID = 'api/v1/Product/GetProductOrderDetailsById';
export const GET_LOCATION_DETAILS_URL = 'api/v1/Product/GetLocationDetails';
export const GET_SCANNED_PRODUCTLIST_URL = 'api/v1/Product/GetMostScannedLocation';
export const GET_TOTAL_SCANCOUNT_URL = 'api/v1/Product/GetTotalScanCount';
export const GET_TOP_PRODUCTS_URL = 'api/v1/Product/GetTopProductGraphData';
export const GET_FILE_FAILURE_NOTI_URL = 'api/v1/Notification/GetAllFailedFileData';
export const GET_FILE_FAILURE_COUNT_URL = 'api/v1/Notification/GetFailedFileNotificationCount';
export const GENERATE_OTP = 'api/v1/Authorize/GenerateOTP';
export const MFA_LOGIN = 'api/v1/Authorize/MFALogin';
export const UPDATE_INFO_ICON_DATA = 'api/v1/Notification/UpdateInfoIconData';
export const GET_ICON_DESCRIPTION_URL = 'api/v1/Notification/GetIconDecriptionById';
export const GET_ALL_EPCISFILE = 'api/v1/Product/GetAllEpcisFile';
export const GET_ALL_RECENT_PRODUCTS = 'api/v1/Product/getAllRecentlyAddedProduct';
export const GET_ALL_WEBLINK_DETAILS = 'api/v1/Product/getWeblinkDetails';
export const UPDATE_WEBLINK_STATUS = 'api/v1/Product/updateWeblinkStatus';
export const UPDATE_WEBLINK_APPROVE_STATUS = 'api/v1/Product/UpdateWeblinkApproveStatus';
export const UPDATE_WEBLINK_REVIEW_STATUS = 'api/v1/Product/UpdateWeblinkReviewStatus';
export const UPDATE_WEBLINK_REJECT_STATUS = 'api/v1/Product/UpdateWeblinkReturnedStatus';
export const GET_ALL_PRODUCT_DASHBOARD_APPROVAL = 'api/v1/Product/GetAllProductDashboardApproval';
export const GET_ALL_NOTIFICATIONS = 'api/v1/Notification/GetAllNotification';
export const UPDATE_NOTIFICATION_STATUS = 'api/v1/Notification/UpdateNotificationReadStatus';
export const GET_NOTIFICATION_COUNT = 'api/v1/Notification/GetAllNotificationCount';
export const GET_ALL_DASHBOARD_AWAITING_ACTION = 'api/v1/Product/GetAllDashboardAwaitingAction';
export const GET_END_USER_SCANNED_DATA_URL = 'api/v1/Product/CheckWeblinkUserScanValid';
export const E_LEAFLET_DOWNLOAD_URL = 'api/v1/Product/ProductFileDownload';
export const GET_SCAN_HISTORY_URL = 'api/v1/Product/GetWebLinkScanHistory';
export const GET_ALL_AREA_SETTINGS_URL = 'api/v1/Master/GetAreaForpasswordAuthentication';
export const GENERATE_AUDIT_TRIAL_REPORT_URL = 'api/v1/Report/AuditLogReportGenerate';
export const GET_ALL_REPORTS_URL = 'api/v1/Report/ReportDetails';
export const GET_ALL_USERNAMES_URL = 'api/v1/Report/GetAllUserName';
export const GET_ALL_MODULES_URL = 'api/v1/Report/GetAllActiveModules';
export const APROOVE_REPORT_URL = 'api/v1/AuditTrial/UpdateReportApproveStatus';
export const REVIEW_REPORT_URL = 'api/v1/AuditTrial/UpdateReportReviewStatus';
export const RETURN_REPORT_URL = 'api/v1/AuditTrial/UpdateReportReturnedStatus';
export const GET_REPORT_PDF_URL = '';
export const GET_ALL_MODULE_URL = 'api/v1/ModuleConfiguration/GetAllModules';
export const UPDATE_MODULE_URL = 'api/v1/ModuleConfiguration/UpdateModuleStatus';
export const GET_ALL_GOOGLE_COUNTRY = 'api/v1/Product/GetAllGoogleCountry';
export const GET_RETRIEVAL_TYPE = '/api/v1/Master/GetAllRetrievalType';
export const UPDATE_PO_RETRIEVAL = 'api/v1/Product/UpdatePORetrieval';
export const GET_PRODUCT_FILE_DOWNLOAD = '/api/v1/Product/ProductFileDownload';
export const SUBMIT_AUDIT_TRIAL_REPORT_URL = 'api/v1/Report/AduitLogReportSubmit';
export const UPDATE_AUDIT_TRAIL_APPROVE_STATUS = 'api/v1/Report/UpdateReportApproveStatus';
export const UPDATE_AUDIT_TRAIL_REVIEW_STATUS = 'api/v1/Report/UpdateReportReviewStatus';
export const UPDATE_AUDIT_TRAIL_RETRUN_STATUS = 'api/v1/Report/UpdateReportReturnedStatus';
export const GET_AUDIT_TRAIL_DETAILS_BY_ID = 'api/v1/Report/ReportView';
export const REPORT_DOWNLOAD_URL = 'api/v1/Report/ReportFileDownload';
export const PACKAGE_LEVEL_BY_PRODUCTID = 'api/v1/Product/GetPackageLevels';
export const GENERATE_BATCH_DETAILS_REPORT_URL = 'api/v1/Report/BatchDetailReportGenerate';
export const GET_BATCH_SUMMARY_DETAILS_BY_ID = 'api/v1/Report/ReportView';
export const SUBMIT_BATCH_SUMMARY_REPORT = 'api/v1/Report/BatchSummaryReportDetailsSubmit';
export const GENERATE_BATCH_SUMMARY_REPORT = 'api/v1/Report/BatchSummaryReportDetailsGenerate';
export const SUBMIT_BATCH_DETAILS_REPORT_URL = 'api/v1/Report/BatchDetailReportSubmit';
export const UPDATE_THEME_URL = 'api/v1/User/UpdateUserTheme';
export const GET_ALL_MANUFACTURERS_DROP_DOWN = 'api/v1/Product/GetAllManufacturerList'; // changed from 'GetAllManufacturerDropDownData' as per API request.
export const GET_ALL_LOCATION_URL = 'api/Location/GetAllLocation';
export const GET_ALL_LOCATION_BY_ID = 'api/Location/GetLocationDetailsById';
export const UPDATE_LANGUAGE_URL = 'api/v1/User/UpdateUserLanguage';
export const GET_ALL_L4_SYSTEM_URL = 'api/v1/L4Management/GetAllL4Systems';
export const UPDATE_L4_SYSTEM = 'api/v1/L4Management/UpdateL4System';
export const ADD_L4_SYSTEM = 'api/v1/L4Management/AddL4System';
export const UPDATE_L4_SYSTEM_STATUS = 'api/v1/L4Management/UpdateL4SystemStatus';
export const GET_ALL_L4_SYSTEM_BY_ID = 'api/v1/L4Management/GetL4SystemById';
export const GET_ALL_LOCATION_TYPE_URL = 'api/Location/GetAllLocationType';
export const GET_ALL_PARENT_LOCATION_URL = 'api/Location/GetAllParentLocation';
export const GET_ALL_ROLE_DATA = 'api/Location/GetAllLocationRoles';
export const ADD_LOCATION_URL = 'api/Location/AddLocation';
export const UPDATE_LOCATION_URL = 'api/Location/UpdateLocationDetails';
export const GET_LOT_NUMBER = 'api/v1/Report/GetAllLotNumber';
export const GET_ALL_PO_BY_LOTNO = 'api/v1/Report/GetAllPoNumber';
export const GET_PRODUCT_BY_LOTNO = 'api/v1/Report/GetAllProductName';
export const GET_ALL_STORAGE_CONDITION = 'api/v1/Product/GetAllStorageCondition';
export const GET_ALL_IVEDA_SCHEDULE = 'api/v1/Product/GetAllIvedaSchedule';
export const UPDATE_WEBLINK_STATUS_IN_VALIDATOR = 'api/v1/Product/UpdateScannedWeblinkStatus';
export const APPROVE_WEBLINK_STATUS_IN_VALIDATOR =
	'api/v1/Product/UpdateScannedWeblinkApproveStatus';
export const REVIEW_WEBLINK_STATUS_IN_VALIDATOR = 'api/v1/Product/UpdateScannedWeblinkReviewStatus';
export const REJECT_WEBLINK_STATUS_IN_VALIDATOR =
	'api/v1/Product/UpdateScannedWeblinkReturnedStatus';
export const UPDATE_LOCATION_STATUS_URL = 'api/Location/UpdateLocationStatus'; // to be changed
export const GET_ALL_ACTIVE_L4_SYSTEM_LIST_URL = 'api/v1/L4Management/GetAllActiveL4Systems';
export const EXPORT_L4_FILE_URL = 'api/v1/L4Management/ExportL4System';
export const GET_FILE_HISTORY_DATA_URL = 'api/v1/L4Management/GetAllL4SystemExportHistory';
export const GET_ALL_TEMPLATE_TYPE = 'api/v1/SerialNumberTemplate/GetAllTemplateType';
export const GET_ALL_TYPE = 'api/v1/SerialNumberTemplate/GetAllSerialNumberType';
export const ADD_SERIAL_NUMBER = 'api/v1/SerialNumberTemplate/AddSerialNumberTemplate';
export const GET_ALL_SERIAL_FILTER_VALUE = 'api/v1/SerialNumberTemplate/GetAllSerialFilterValue';
export const GET_ALL_SERIAL_NUMBER = 'api/v1/SerialNumberTemplate/GetAllSerialNumberTemplateList';
export const UPDATE_SERIAL_NUMBER_STATUS_URL =
	'api/v1/SerialNumberTemplate/UpdateSerialNumberTemplateStatus';
export const GET_PO_SERIAL_NUMBER_LIST =
	'api/v1/SerialNumberTemplate/GetSerialNumberListByLotNumberID';
export const GET_SERIAL_NUMBER_LIST_WITH_TYPE =
	'api/v1/SerialNumberTemplate/GetSerialNumberListWithType';
export const GET_WEBLINK_HISTORY_URL = 'api/v1/Product/GetWebLinkHistoryData';
export const GET_WEBLINK_REPORT_DATA_URL = 'api/v1/Report/GetWebLinkReportData';
export const GET_ALL_L3_SYSTEM_NAME_URL = 'api/v1/Report/GetAllL3SystemName';
export const GET_ALL_GTIN_BY_SEARCH_TEXT_URL = 'api/v1/Report/GetGTINBySearchText';
export const GET_ALL_SSO = 'api/v1/SSO/GetAllSamlDetails';
export const UPDATE_SSO = 'api/v1/SSO/AddSamlDetails';
export const GET_SSO_XML_DOWNLOAD = 'api/v1/SSO/XMLFileDownload';
export const GET_SSO_CRT_DOWNLOAD = 'api/v1/SSO/CrtFileDownload';
export const XML_FILE_GENERATE = 'api/v1/SSO/XMLFileGenerate';
export const SSO_LOGIN_URL_REQUEST = 'api/v1/SSO/login';
export const SSO_LOGIN_GENERATE_TOKEN = 'api/v1/Authorize/GenerateTokenAndRefreshToken';
export const EPCIS_DOWNLOAD_URL = 'api/v1/Product/EPCISFileDownload';
export const IMPORT_EPCIS_FILE_URL = 'api/v1/Product/EPCISXMLFileUpload';
export const GET_ALL_GS1_DETAILS = 'api/v1/GS1WebLink/GetAllGS1SettingIds';
export const GET_ALL_MODULE_SIZE = 'api/v1/GS1WebLink/GetAllModuleQRSizeData';
export const UPDATE_GS1_WEBLINK = 'api/v1/GS1WebLink/SaveGS1WeblinkFormat';
export const GET_ALL_WEBLINK_TYPE = 'api/v1/GS1WebLink/GetAllWeblinkType';

export const GET_SERIAL_NUMBER_GTIN_NUMBER_BY_SEARCH_TEXT =
	'api/v1/SerialNumberTemplate/GetGtnNumberBySearchText';
export const DOWNLOAD_WEBLINK_REPORT = 'api/v1/Report/GetWebLinkReport';
export const DELETE_SERIAL_NUMBER = 'api/v1/SerialNumberTemplate/DeleteSerialNumberTemplate';
export const GET_ALL_ACTIVE_L3_SYSTEM_NAME = 'api/v1/L3Management/GetAllActiveL3Systems';
export const GET_ALL_GTIN_L3 = 'api/v1/L3Management/GetAllGTIN';
export const GENERATE_WEBLINK = 'api/v1/L3Management/SaveOfflineWeblinkRequest';
export const GET_ALL_WEBLINK_DOWNLOAD = 'api/v1/L3Management/GetAllOfflineWeblinkRequests';
export const DOWNLOADWEBLINKCSV = 'api/v1/L3Management/OfflineWeblinkDownload';
export const LOGOUT_URL = 'api/v1/Authorize/LogOut';
/**
 * weblink settings URL's
 */
export const ADD_WEBLINK_SETTINGS_URL = 'api/v1/WeblinkSettings/AddGlobalWeblinkSettings';
export const GETALLWEBLINKSETTINGS_URL = 'api/v1/WeblinkSettings/GetAllWeblinkSettings';
export const DELETE_WEBLINK_SETTINGS_URL = 'api/v1/WeblinkSettings/DeleteWeblinkSettings';
export const WEBLINK_REQUEST_STATUS_UPDATE_URL =
	'api/v1/WeblinkSettings/WeblinkRequestStatusUpdate';
export const UPDATE_SERIAL_NUMBER = 'api/v1/SerialNumberTemplate/UpdateSerialNumberDetails';
