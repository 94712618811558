import { Route, Routes } from 'react-router-dom';

import { RouteUrl } from 'utils/enum';

import ResetPasswordWrapper from 'pages/ResetPassword';
import ProtectedRoutes from 'layout/ProtectedRoutes';
import SettingsPage from 'pages/Settings';
import ForgotPasswordWrapper from 'pages/ForgotPassword';
import Tenant from 'pages/Tenant';
import AddProducts from 'components/productModule/addProducts/AddProducts';
import ProductGroup from 'pages/ProductGroup';
import AddProductGroup from 'components/productModule/productGroup/AddProductGroup';
import TenentRedirection from 'components/tenant/TenentRedirection';
import Manufacturer from 'pages/Manufacturer';
import Product from 'pages/Product';
import PackageLevel from 'pages/PackageLevel';
import L3SystemPage from 'pages/L3SystemPage';
import AddEditL3System from 'components/l3System/AddEditL3System';
import WeblinkValidatorPage from 'pages/WeblinkValidatorPage';
import OtpVerificationPage from 'pages/OtpVerificationPage';
import AwaitingActions from 'pages/AwaitingActions';
import EPCISModule from 'pages/EPCISModule';
import ProductOrderDetails from 'pages/ProductOrderDetails';
import FileFailureListContainer from 'components/fileFailure/FileFailureListContainer';
import WeblinkStatus from 'pages/WeblinkStatus';
import Notifications from 'pages/Notifications';
import ModuleConfiguration from 'pages/ModuleConfiguration';
import EndUserScan from 'pages/EndUserScan';
import PORetrievalPage from 'pages/PORetrievalPage';
import AuditTrailDetailsPage from 'pages/AuditTrailDetailsPage';
// import BatchSummaryReportPage from 'pages/BatchSummaryReportPage';
import BatchReportDetails from 'components/batchDetailsReport/BatchReportDetails';
// import GenerateBatchSummary from 'components/report/batchSummaryReport/GenerateBatchSummary';
import AuditTrialPage from 'pages/AuditTrialPage';
import BatchDetailsPage from 'pages/BatchDetailsPage';
import LocationPage from 'pages/LocationPage';
import L4System from 'pages/L4System';
import AddEditL4System from 'components/l4System/AddEditL4System';
import AddEditLocation from 'components/location/AddEditLocation';
import ExportHistoryL4 from 'components/batchPO/ExportHistoryL4';
import AddSerialNumberTemplate from 'components/serialNumberManagement/AddSerialNumberTemplate';
import SerialNumberPage from 'pages/SerialNumberPage';
import POSerialNumberList from 'components/batchPO/POSerialNumberList';
import SSO from 'pages/SSO';
import SsoLogin from 'pages/sso-login/SsoLogin';
import SsoLoginSuccess from 'pages/sso-login/SsoLoginSuccess';
import WeblinkReportPage from 'pages/WeblinkReportPage';
import GenerateWeblinkPage from 'pages/GenerateWeblinkPage';
import DownloadWeblinkPage from 'pages/DownloadWeblinkPage';
import WeblinkSettingsPage from 'pages/WeblinkSettingsPage';
import MultipleLogin from 'pages/authentication/MultipleLogin';
import Page404 from '../pages/authentication/Page404';
import Layout from '../layout/Index';
import DashBoard from '../pages/DashBoard';
import Login from '../pages/Login';
import UserModule from '../pages/UserModule';
import RoleModule from '../pages/RoleModule';
import UnAuthLayout from '../layout/unAuthLayout/Index';
import UnAuthorizedAccess from '../pages/authentication/UnAuthorizedAccess';
import ProductOrder from '../pages/ProductOrder';
import Index from '../layout/defaultLayout/Index';

const App = () => {
	return (
		<Routes>
			<Route element={<UnAuthLayout />}>
				<Route path={RouteUrl.LOGINPAGE} element={<Login />} />
				<Route path={RouteUrl.RESETPASSWORD} element={<ResetPasswordWrapper />} />
				<Route path={RouteUrl.FORGOTPASSWORD} element={<ForgotPasswordWrapper />} />
				<Route path={RouteUrl.OTPVERIFICATION} element={<OtpVerificationPage />} />
			</Route>
			<Route element={<Index />}>
				<Route path={RouteUrl.MULTIPLE_SESSION} element={<MultipleLogin />} />
			</Route>

			<Route
				element={
					<ProtectedRoutes redirectPath={RouteUrl.UNAUTHORIZEDACCESS}>
						<Layout />
					</ProtectedRoutes>
				}>
				<Route path={RouteUrl.USERMODULE} element={<UserModule />} />
				<Route path={RouteUrl.ROLEMODULE} element={<RoleModule />} />
				<Route path={RouteUrl.TENANTMODULE} element={<Tenant />} />
				<Route path={RouteUrl.WEBLINKVALIDATOR} element={<WeblinkValidatorPage />} />
				<Route path={RouteUrl.WEBLINKSTATUS} element={<WeblinkStatus />} />
				<Route path={RouteUrl.EPCISMODULE} element={<EPCISModule />} />
				<Route path={RouteUrl.SSO} element={<SSO />} />
				<Route path={RouteUrl.MODULECONFIGURATIONS} element={<ModuleConfiguration />} />

				{/* settings */}
				<Route path={RouteUrl.SETTINGS} element={<SettingsPage />} />
				<Route path={RouteUrl.WEBLINK_SETTINGS} element={<WeblinkSettingsPage />} />

				{/* dashboard */}
				<Route path={RouteUrl.DASHBOARD} element={<DashBoard />} />
				<Route path={RouteUrl.AWAITINGACTIONS} element={<AwaitingActions />} />
				<Route path={RouteUrl.ALLNOTIFICATIONS} element={<Notifications />} />
				<Route path={RouteUrl.FILE_FAILURE_LIST} element={<FileFailureListContainer />} />

				{/* product routes */}
				<Route path={RouteUrl.PRODUCTGROUP} element={<ProductGroup />} />
				<Route path={RouteUrl.ADDPRODUCTGROUP} element={<AddProductGroup />} />
				<Route path={RouteUrl.ADDPRODUCTS} element={<AddProducts />} />
				<Route path={RouteUrl.PRODUCTMODULE} element={<Product />} />
				<Route path={RouteUrl.MANUFACTURER} element={<Manufacturer />} />
				<Route path={RouteUrl.PACKAGELEVEL} element={<PackageLevel />} />

				{/* L3 routes */}
				<Route path={RouteUrl.L3SYSTEM} element={<L3SystemPage />} />
				<Route path={RouteUrl.ADDL3SYSTEM} element={<AddEditL3System />} />

				{/* PO routes */}
				<Route path={RouteUrl.PRODUCTORDER} element={<ProductOrder />} />
				<Route path={RouteUrl.PODETAILS} element={<ProductOrderDetails />} />
				<Route path={RouteUrl.PORETRIEVAL} element={<PORetrievalPage />} />

				{/* AuditTrial routes */}
				<Route path={RouteUrl.AUDIT_TRIAL} element={<AuditTrialPage />} />
				<Route path={RouteUrl.AUDIT_SHOW} element={<AuditTrailDetailsPage />} />

				{/* Batchdetails routes */}
				<Route path={RouteUrl.BATCH_DETAILS} element={<BatchDetailsPage />} />
				<Route path={RouteUrl.BATCH_DETAILS_SHOW} element={<BatchReportDetails />} />

				{/* <Route path={RouteUrl.BATCH_SUMMARY_REPORT} element={<BatchSummaryReportPage />} /> */}
				{/* <Route path={RouteUrl.GENERATE_BATCH_SUMMARY} element={<GenerateBatchSummary />} /> */}

				{/* Location */}
				<Route path={RouteUrl.LOCATION_DETAILS} element={<LocationPage />} />
				<Route path={RouteUrl.ADD_EDIT_LOCATION} element={<AddEditLocation />} />

				{/* L4 routes */}
				<Route path={RouteUrl.L4SYSTEM} element={<L4System />} />
				<Route path={RouteUrl.ADDL4SYSTEM} element={<AddEditL4System />} />
				<Route path={RouteUrl.EXPORT_HISTORY_PAGE} element={<ExportHistoryL4 />} />

				{/* weblink */}
				<Route path={RouteUrl.WEBLINK_REPORT} element={<WeblinkReportPage />} />
				<Route path={RouteUrl.WEBLINK_DOWNLOAD} element={<DownloadWeblinkPage />} />
				<Route
					path={RouteUrl.GENERATE_WEBLINK_DOWNLOAD}
					element={<GenerateWeblinkPage />}
				/>

				{/* serial number */}
				<Route path={RouteUrl.SERIAL_NUMBER_MANAGEMENT} element={<SerialNumberPage />} />
				<Route
					path={RouteUrl.ADD_SERIAL_NUMBER_TEMPLATE}
					element={<AddSerialNumberTemplate />}
				/>
				<Route
					path={RouteUrl.PRODUCT_ORDER_SERIAL_NUMBER}
					element={<POSerialNumberList />}
				/>
			</Route>

			<Route path='/tenant/:token' element={<TenentRedirection />} />
			<Route path={RouteUrl.UNAUTHORIZEDACCESS} element={<UnAuthorizedAccess />} />
			<Route path={RouteUrl.SSO_LOGIN} element={<SsoLogin />} />
			<Route path={RouteUrl.SSO_SUCCESS} element={<SsoLoginSuccess />} />

			{/* End user scan page */}

			<Route path='*' element={<EndUserScan />} />

			{/* commenting these 2 enduser routes because all unspecified routes will
			 navigate to enduser page using * navigate route  */}
			{/* <Route path='/:urlprefix/:id/*' element={<EndUserScan />} />
			<Route path='/01/:number/*' element={<EndUserScan />} /> */}

			<Route path={RouteUrl.PAGE_404} element={<Page404 />} />
		</Routes>
	);
};

export default App;
