import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faClose,
	faHome,
	faChartPie,
	faAddressCard,
	faUser,
	faQuestion,
	faGear,
	faBell,
	faEllipsisVertical,
	faTrash,
	faEdit,
	faAdd,
	faArrowUp,
	faUpDown,
	faInfoCircle,
	faIndustry,
	faBoxesPacking,
	faBox,
	faPlus,
	faLayerGroup,
	faCartPlus,
	faCube,
	faChevronRight,
	faAngleDown,
	faLink,
	faNetworkWired,
	faArrowLeft,
	faMinus,
	faFileExcel,
	faFile,
	faCalendar,
	faCheckDouble,
	faCircle,
	faFilePdf,
	faAngleRight,
	faFileLines,
	faLocationCrosshairs,
	faServer,
	faListOl,
	faDownload,
	faEye,
	faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { IShowIconPropType } from '../model';

/**
 *
 * @param param  name of the icon, prefix to add, Style and size of the icon
 * @returns Favicon jsx element
 */
const ShowIcon = ({ name, prefix, style, size }: IShowIconPropType) => {
	const icons: any = {
		faClose,
		faHome,
		faChartPie,
		faAddressCard,
		faUser,
		faQuestion,
		faGear,
		faBell,
		faEllipsisVertical,
		faTrash,
		faEdit,
		faAdd,
		faArrowUp,
		faUpDown,
		faInfoCircle,
		faIndustry,
		faBoxesPacking,
		faBox,
		faPlus,
		faLayerGroup,
		faCartPlus,
		faCube,
		faChevronRight,
		faAngleDown,
		faLink,
		faNetworkWired,
		faArrowLeft,
		faMinus,
		faFileExcel,
		faFile,
		faCalendar,
		faCheckDouble,
		faCircle,
		faFilePdf,
		faAngleRight,
		faFileLines,
		faLocationCrosshairs,
		faServer,
		faListOl,
		faDownload,
		faEye,
		faEyeSlash,
	};

	// Getting icon from the icons object based on name
	const getIcon = () => {
		const iconValue = icons[name];
		if (prefix) {
			return { ...iconValue, prefix };
		}
		return iconValue;
	};

	return <FontAwesomeIcon icon={getIcon()} style={style || undefined} size={size || undefined} />;
};

export default ShowIcon;
