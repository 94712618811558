import { useEffect, useState } from 'react';

import { axiosBasicInstance } from 'libs/axios';
import { VALIDATE_USER_TOKEN } from 'utils/serviceUrls';
/**
 *
 * @param paramData -which includes the token
 * @returns validate token loading,validate token reponse status and validate token reponse
 */
const useValidateUserToken = (paramData: any) => {
	const [validateTokenloading, setValidateTokenLoading] = useState<boolean>(false);
	const [validateresponseStatus, setValidateResponseStatus] = useState<boolean>(false);
	const [validateresponse, setValidateResponse] = useState<any>({});

	useEffect(() => {
		setValidateTokenLoading(true);
		if (paramData) {
			axiosBasicInstance
				.post(VALIDATE_USER_TOKEN, paramData)
				.then((response: any) => {
					setValidateTokenLoading(false);
					if (response?.statusCode === '200') {
						setValidateResponseStatus(true);
						setValidateResponse(response.output);
					}
				})
				.catch(() => {
					setValidateTokenLoading(false);
				});
		}
	}, [paramData]);

	return {
		validateTokenloading,
		validateresponseStatus,
		validateresponse,
	};
};

export default useValidateUserToken;
