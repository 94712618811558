/**
 * Component for a Kendo Text Editor.
 * @param {Object} props - The props passed to the component.
 * @param {string} props.text - The initial text content of the editor.
 * @param {function} props.updateText - The callback function to update the editor's text content.
 * @param {boolean} props.infoIconDataLoading - A boolean indicating whether the data is being loaded.
 * @param {function} props.handleErrorUpdate - The callback function to handle error updates in the editor.
 */

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor, EditorTools } from '@progress/kendo-react-editor';

// eslint-disable-next-line import/no-extraneous-dependencies
import CustomButton from '../button/CustomButton';
import CustomLoader from '../loader/CustomLoader';

const KendoTextEditor = ({ text, updateText, infoIconDataLoading, handleErrorUpdate }: any) => {
	const { t } = useTranslation('productModule');
	const editor = useRef<any>(null);

	const { Bold, Italic, Underline, UnorderedList } = EditorTools;

	/**
	 * Handles the update of the text in the editor.
	 */
	const handleUpdate = () => {
		updateText(editor.current);
	};

	/**
	 * Handles the onChange event of the editor to handle error updates.
	 */
	const handleOnChange = () => {
		handleErrorUpdate();
	};

	return (
		<>
			<Editor
				ref={editor}
				tools={[Bold, Italic, Underline, UnorderedList]}
				contentStyle={{
					height: 200,
					width: 200,
				}}
				defaultContent={text}
				onChange={handleOnChange}
			/>

			<CustomButton
				type='button'
				buttonClassName='btn btn-primary updateBtn'
				buttonName={infoIconDataLoading ? <CustomLoader /> : t('UPDATE')!!}
				handleClick={handleUpdate}
			/>
		</>
	);
};
export default KendoTextEditor;
