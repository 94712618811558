/**
 * Custom No Data component.
 * @param {Object} props - The props passed to the component.
 * @param {string} props.notificationType - The type of notification to display (e.g., "Error", "Warning", etc.).
 * @returns {JSX.Element} - The rendered component.
 */
import { useTranslation } from 'react-i18next';

const CustomNoData = ({ notificationType }: any) => {
	/**
	 * The react-i18next hook for translations.
	 */
	const { t } = useTranslation('dashboard');

	return <div className='text-danger'>{`${notificationType} ${t('DATAS_NOT_FOUND')}`}</div>;
};

export default CustomNoData;
