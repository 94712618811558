/**
 * Common back button on pages
 
 * @param {string} props.url - url to nagvigate. usually given -1 as react router does automatically handles it.

 * @returns {JSX.Element} - The rendered common back button.
 */
import { useNavigate } from 'react-router-dom';
import CustomButton from '../button/CustomButton';

const PageBackButton = ({ url }) => {
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(url);
	};
	return (
		<CustomButton
			buttonClassName='btn back-btn'
			handleClick={handleGoBack}
			type='button'
			icon='faArrowLeft'
		/>
	);
};

export default PageBackButton;
