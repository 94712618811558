import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { ADD_LOCATION_URL } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useAddLocation = (locationData: any, isSubmitted: boolean, setIsSubmitted: any) => {
	const { t } = useTranslation('locationModule');

	const params = {
		location: locationData.locationName,
		gln: locationData.gln,
		sgln: locationData.sgln,
		sglnExtension: locationData.sglnExtension,
		encParentLocationId: locationData.encParentLocationId,
		encLocationRoleId: locationData.encLocationRoleId,
		locationOwnerName: locationData.locationOwnerName,
	};
	const [addLocationLoading, setAddLocationLoading] = useState<boolean>(false);
	const [addLocationResponse, setAddLocationResponse] = useState<any>({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (isSubmitted) {
			setAddLocationLoading(true);
			axiosPrivateInstance
				.post(ADD_LOCATION_URL, params)
				.then((response: any) => {
					setAddLocationResponse(response);
					if (response) {
						if (response?.status) {
							setAddLocationLoading(false);
							dispatch(setMessage(t('LOCATION_ADDED_SUCCESSFULLY')));
							dispatch(setShowNotification(true));
							dispatch(setNotificationType('success'));
						} else {
							setAddLocationLoading(false);
							dispatch(
								setMessage(t(response.output.resultText || 'LOCATION_ADD_FAILED')),
							);
							dispatch(setShowNotification(true));
							dispatch(setNotificationType('error'));
						}
					} else {
						setAddLocationLoading(false);
					}

					setAddLocationLoading(false);
				})
				.catch(() => {
					setAddLocationLoading(false);
				});
		}

		return () => {
			setIsSubmitted(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		addLocationLoading,
		addLocationResponse,
	};
};

export default useAddLocation;
