import { IDetailCardData } from 'components/common/model';
import { useTranslation } from 'react-i18next';

const DetailCard = ({ resData, type }: IDetailCardData) => {
	const { t } = useTranslation('l3System');

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{type === 'product' ? (
				<ul className='weblink-wrapper'>
					<li>
						<span className='link-head'>{t('PRODUCT_NAME')}</span>
						<span className='link-separator'>:</span>&nbsp;{resData?.productName}
					</li>
					<li>
						<span className='link-head'>{t('GTIN')}</span>
						<span className='link-separator'>:</span>&nbsp;{resData?.gtinNo}
					</li>
					<li>
						<span className='link-head'>{t('PRODUCT_CODE')}</span>
						<span className='link-separator'>:</span>&nbsp;{resData?.productCode}
					</li>
					<li>
						<span className='link-head'>{t('PRODUCT_MANUFACTURER')}</span>
						<span className='link-separator'>:</span>&nbsp;{resData?.manufacturerName}
					</li>
				</ul>
			) : (
				<ul className='weblink-wrapper'>
					<li>
						<span className='link-head'>{t('PRODUCT_ORDER')}</span>
						<span className='link-separator'>:</span>&nbsp;{resData?.productionOrder}
					</li>
					<li>
						<span className='link-head'>{t('PRODUCT_NAME')}</span>
						<span className='link-separator'>:</span>&nbsp;{resData?.productName}
					</li>
					<li>
						<span className='link-head'>{t('GTIN')}</span>
						<span className='link-separator'>:</span>&nbsp;{resData?.gtinNo}
					</li>
				</ul>
			)}
		</>
	);
};
export default DetailCard;
