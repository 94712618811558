import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ShowIcon from 'components/common/fontAwesome/ShowIcon';
import CustomPopover from 'components/tenant/CustomPopover';
import FileFailedDropdown from './FileFailedDropdown';
import useGetFileFailedCount from './hooks/useGetFileFailedCount';

const FileFailedNotification = () => {
	const popoverRef = useRef<any>(null);

	const { t } = useTranslation('fileFailure');

	const [showFileFailurePopover, setShowFileFailurePopover] = useState<boolean>(false);
	const [notificationCount, setNotificationCount] = useState<number>(0);

	const { fileFailureCountDataRes } = useGetFileFailedCount();

	useEffect(() => {
		if (fileFailureCountDataRes && fileFailureCountDataRes.requestCount !== undefined) {
			setNotificationCount(fileFailureCountDataRes.requestCount);
		}
	}, [fileFailureCountDataRes]);

	const onFileFailedClick = (e: any) => {
		setShowFileFailurePopover(!showFileFailurePopover);
		e.preventDefault();
		e.stopPropagation();
	};

	const hidePopover = () => {
		setShowFileFailurePopover(false);
	};

	// to close the file failed popover on outside click
	useEffect(() => {
		function handleClickOutside(event) {
			const element = document.getElementsByClassName('k-popover-inner');
			const btnElement = document.getElementsByClassName('file-failed-btn');
			if (
				element[0] &&
				!element[0].contains(event.target) &&
				!btnElement[0].contains(event.target)
			) {
				setShowFileFailurePopover(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<div
				className='file-failed-btn'
				ref={popoverRef}
				onClick={(e: any) => onFileFailedClick(e)}>
				<ShowIcon name='faFileExcel' />
				<label>{t('FILE_FAILED')!!}</label>
				<span className='position-absolute start-100 translate-middle badge rounded-pill'>
					{notificationCount}
				</span>
			</div>

			<CustomPopover
				className='header-popover'
				anchor={popoverRef.current && popoverRef.current}
				show={showFileFailurePopover}
				position='bottom'>
				<FileFailedDropdown
					notificationCount={notificationCount}
					hidePopover={hidePopover}
				/>
			</CustomPopover>
		</>
	);
};

export default FileFailedNotification;
