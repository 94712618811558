import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import CustomButton from 'components/common/button/CustomButton';
import CustomInput from 'components/common/forms/CustomInput';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomError from 'components/common/errorLabel/CustomError';
import AddEditLocationSkeleton from 'components/common/skeleton/AddEditLocationSkeleton';
import CustomLoader from 'components/common/loader/CustomLoader';
import { productFormValidations } from 'utils/utils';
import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID, RouteUrl } from 'utils/enum';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import CustomDropDown from '../common/forms/CustomDropdown';
import useGetLocationById from './hooks/useGetLocationById';
import useGetAllParentLocation from './hooks/useGetAllParentLocation';
import useGetAllRoleData from './hooks/useGetAllRoleData';
import useAddLocation from './hooks/useAddLocation';
import useUpdateLocation from './hooks/useUpdateLocation';

const AddEditLocation = () => {
	const { t } = useTranslation('locationModule');
	const location = useLocation();
	const navigate = useNavigate();

	const [locationName, setLocationName] = useState<string>('');
	const [glnValue, setGlnValue] = useState<string>('');

	const [sglnDetails, setSglnDetails] = useState<string>('');
	const [sglnValue, setSglnValue] = useState<string>('');
	const [ownerName, setOwnerName] = useState<string>('');
	const [roleDataList, setRoleDataList] = useState<any>([]);
	const [parentLocationDataList, setParentLocationDataList] = useState<any>([]);

	const [selectedRoleId, setSelectedRoleId] = useState<string>('');

	const [selectedParentLocationId, setSelectedParentLocationId] = useState<string>('');

	const [errorState, setErrorState] = useState<Record<string, string>>({});

	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [isEditSubmitted, setIsEditSubmitted] = useState<boolean>(false);

	const [locationAddEditInfo, setLocationAddEditInfo] = useState<string>('');

	// hook to get data by Id
	const { resData, locationLoading } = useGetLocationById(location?.state?.locationId);

	const { roleResponseData } = useGetAllRoleData();
	const { parentLocationResponseData } = useGetAllParentLocation();

	// sets icon description data in state
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.LOCATION_DETAILS,
		ICON_DESCRIPTION_ID.LOCATION_ADD_EDIT,
	);

	const { addLocationLoading, addLocationResponse } = useAddLocation(
		{
			locationName,
			gln: glnValue,
			sgln: sglnValue,
			sglnExtension: sglnDetails,
			encParentLocationId: selectedParentLocationId,
			encLocationRoleId: selectedRoleId,
			locationOwnerName: ownerName,
		},
		isSubmitted,
		setIsSubmitted,
	);

	const { updateLocationLoading, updateLocationResponse } = useUpdateLocation(
		{
			locationName,
			gln: glnValue,
			sgln: sglnValue,
			sglnExtension: sglnDetails,
			encParentLocationId: selectedParentLocationId,
			encLocationRoleId: selectedRoleId,
			locationOwnerName: ownerName,
			encLocationId: location?.state?.locationId,
		},
		isEditSubmitted,
		setIsEditSubmitted,
	);

	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setLocationAddEditInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	useEffect(() => {
		roleResponseData && roleResponseData.length > 0 && setRoleDataList(roleResponseData);
	}, [roleResponseData]);

	useEffect(() => {
		parentLocationResponseData &&
			parentLocationResponseData.length > 0 &&
			setParentLocationDataList(parentLocationResponseData);
	}, [parentLocationResponseData]);

	useEffect(() => {
		if (addLocationResponse.status || updateLocationResponse.status) {
			navigate(RouteUrl.LOCATION_DETAILS);
		}
	}, [addLocationResponse, updateLocationResponse]);

	// reseting states
	const reset = () => {
		setLocationName('');
		setGlnValue('');
		setSglnValue('');
		setSglnDetails('');
		setOwnerName('');
		setSelectedRoleId('');
		setSelectedParentLocationId('');
		setErrorState({});
	};

	// handle changes of input section
	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
			if (event.target.name === 'locationName') {
				setErrorState((prev) => ({ ...prev, locationName: '' }));
				setLocationName(event.target.value ? event.target.value.toString() : '');
			} else if (event.target.name === 'gln') {
				setErrorState((prev) => ({ ...prev, glnValue: '' }));
				setGlnValue(event.target.value ? event.target.value.toString() : '');
			} else if (event.target.name === 'sgln') {
				setErrorState((prev) => ({ ...prev, sglnValue: '' }));
				setSglnValue(event.target.value ? event.target.value.toString() : '');
			} else if (event.target.name === 'sglnDetails') {
				setErrorState((prev) => ({ ...prev, sglnDetails: '' }));
				setSglnDetails(event.target.value ? event.target.value.toString() : '');
			} else if (event.target.name === 'ownerName') {
				setErrorState((prev) => ({ ...prev, ownerName: '' }));
				setOwnerName(event.target.value ? event.target.value.toString() : '');
			}
		},
		[],
	);

	// setting states to input fields from getbyId hook
	useEffect(() => {
		if (resData) {
			setLocationName(resData?.locationName);
			setGlnValue(resData?.glnNo);
			setSglnDetails(resData?.sglnExtensionDetails);
			setSglnValue(resData?.sgln);
			setOwnerName(resData?.locationOwnerName);
			setSelectedRoleId(resData?.encLocationRoleId ? resData?.encLocationRoleId : '');
			setSelectedParentLocationId(
				resData?.encParentLocationId ? resData?.encParentLocationId : '',
			);
		}
		return () => {
			reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resData]);

	// handle change of locationType dropdown
	const onRoleChange = useCallback((event: any) => {
		setSelectedRoleId(event);
		setErrorState((prev) => ({ ...prev, selectedRoleId: '' }));
	}, []);

	// handle change of locationType dropdown
	const onParentLocationChange = useCallback((event: any) => {
		setSelectedParentLocationId(event);
		setErrorState((prev) => ({ ...prev, selectedParentLocationId: '' }));
	}, []);

	// validation of input fields
	const handleSubmit = () => {
		const error = productFormValidations(
			{
				locationName,
				glnValue,
				sglnValue,
				sglnDetails,
				ownerName,
				selectedRoleId,
				selectedParentLocationId,
			},
			{
				locationName: { required: true },
				glnValue: { required: true },
				sglnValue: { required: true },
				sglnDetails: { required: true },
				ownerName: { required: true },
				selectedRoleId: { required: true },
				selectedParentLocationId: { required: true },
			},
		);

		setErrorState(error);

		if (Object.keys(error).length === 0) {
			if (location?.state?.type === 'add') {
				setIsSubmitted(true);
			} else setIsEditSubmitted(true);
		}
	};

	return (
		<div className='page-content add-product-group-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3>
						{location?.state?.type === 'add'
							? `${t('CREATE_LOCATION')}`
							: `${t('EDIT_LOCATION')}`}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={locationAddEditInfo}
							moduleId={ICON_DESCRIPTION_ID.LOCATION_ADD_EDIT}
						/>
					</h3>
				</div>
			</div>

			<div className='page-content-body'>
				<div className='box-container product-order-details-container'>
					{locationLoading ? (
						<AddEditLocationSkeleton />
					) : (
						<form>
							<fieldset className='k-form-fieldset default-form-style tenant-content-body'>
								<div className='row'>
									<div className='col-xl-3 col-lg-3 col-sm-6'>
										<div className='form-group'>
											<CustomInput
												className={
													errorState?.locationName &&
													errorState?.locationName !== ''
														? 'input-validation-error'
														: ''
												}
												value={locationName}
												onChange={handleChange}
												name='locationName'
												label={t('LOCATION_NAME')!!}
												placeholder={t('ENTER_LOCATION_NAME')}
												required
												type='text'
												error={errorState.locationName}
												isMandatory
											/>
										</div>
									</div>

									<div className='col-xl-3 col-lg-3 col-sm-6'>
										<div className='form-group'>
											<CustomInput
												className={
													errorState?.glnValue &&
													errorState?.glnValue !== ''
														? 'input-validation-error'
														: ''
												}
												value={glnValue}
												onChange={handleChange}
												name='gln'
												label={t('GLN')!!}
												placeholder={t('ENTER_GLN')}
												required
												type='text'
												error={errorState.glnValue}
												isMandatory
											/>
										</div>
									</div>

									<div className='col-xl-3 col-lg-3 col-sm-6'>
										<div className='form-group'>
											<CustomInput
												className={
													errorState?.sglnDetails &&
													errorState?.sglnDetails !== ''
														? 'input-validation-error'
														: ''
												}
												value={sglnDetails}
												onChange={handleChange}
												name='sglnDetails'
												label={t('SGLN_DETAILS')!!}
												placeholder={t('ENTER_SGLN_DETAILS')}
												required
												type='text'
												error={errorState.sglnDetails}
												isMandatory
											/>
										</div>
									</div>

									<div className='col-xl-3 col-lg-3 col-sm-6'>
										<div className='form-group'>
											<CustomInput
												className={
													errorState?.sglnValue &&
													errorState?.sglnValue !== ''
														? 'input-validation-error'
														: ''
												}
												value={sglnValue}
												onChange={handleChange}
												name='sgln'
												label={t('SGLN')!!}
												placeholder={t('ENTER_SGLN')}
												required
												type='text'
												error={errorState.sglnValue}
												isMandatory
											/>
										</div>
									</div>

									<div className='col-xl-3 col-lg-3 col-sm-6'>
										<div className='form-group'>
											<CustomInput
												className={
													errorState?.ownerName &&
													errorState?.ownerName !== ''
														? 'input-validation-error'
														: ''
												}
												value={ownerName}
												onChange={handleChange}
												name='ownerName'
												label={t('LOCATION_OWNER_NAME')!!}
												placeholder={t('ENTER_OWNER_NAME')}
												required
												type='text'
												error={errorState.ownerName}
												isMandatory
											/>
										</div>
									</div>

									<div className='col-xl-3 col-lg-3 col-sm-6'>
										<div className='form-group'>
											<label className='form-label'>{t('ROLE')}</label>
											<CustomDropDown
												isId
												popupSettings={{
													popupClass: 'default-select-dropdown',
												}}
												className={`form-control default-select-dropdown ${
													errorState?.selectedRoleId &&
													errorState?.selectedRoleId !== ''
														? 'input-validation-error'
														: ''
												}`}
												dropdownValue={selectedRoleId}
												isMandatory
												handleDropDownChange={onRoleChange}
												options={roleDataList}
												dataItemKey='encLocationRoleId'
												dataTextField='locationRoleName'
											/>

											{errorState?.selectedRoleId &&
												errorState?.selectedRoleId !== '' && (
													<CustomError
														value={errorState.selectedRoleId}
													/>
												)}
										</div>
									</div>

									<div className='col-xl-3 col-lg-3 col-sm-6'>
										<div className='form-group'>
											<label className='form-label'>
												{t('PARENT_LOCATION')}
											</label>
											<CustomDropDown
												isId
												popupSettings={{
													popupClass: 'default-select-dropdown',
												}}
												className={`form-control default-select-dropdown ${
													errorState?.country &&
													errorState?.country !== ''
														? 'input-validation-error'
														: ''
												}`}
												dropdownValue={selectedParentLocationId}
												isMandatory
												handleDropDownChange={onParentLocationChange}
												options={parentLocationDataList}
												dataItemKey='encParentLocationId'
												dataTextField='LocationName'
											/>

											{errorState?.selectedParentLocationId &&
												errorState?.selectedParentLocationId !== '' && (
													<CustomError
														value={errorState.selectedParentLocationId}
													/>
												)}
										</div>
									</div>

									<div className='k-form-buttons default-offcanvas-footer'>
										<CustomButton
											buttonClassName='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-primary h-44px w-100'
											type='button'
											buttonName={
												addLocationLoading || updateLocationLoading ? (
													<CustomLoader />
												) : (
													t(
														`${
															location?.state?.type === 'add'
																? 'CREATE_BUTTON'
																: 'UPDATE_BUTTON'
														}`,
													)
												)
											}
											handleClick={handleSubmit}
										/>
									</div>
								</div>
							</fieldset>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};

export default AddEditLocation;
