import { useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DrawerItem, DrawerItemProps } from '@progress/kendo-react-layout';
import Dropdown from 'react-bootstrap/Dropdown';

import { RouteUrl } from 'utils/enum';

import ShowIcon from 'components/common/fontAwesome/ShowIcon';
import MainLogo from 'assets/images/ALSC-Logo-menu.svg';
import { ISidebarItem } from '../../components/common/model';

/**
 * SidebarCustom component.
 * @param {Object} props - The props passed to the component.
 */
const SidebarCustom = (props: DrawerItemProps) => {
	/**
	 * Object interface for a sidebar item.
	 * @typedef {Object} ISidebarItem
	 * @property {string} id - The id of the item.
	 * @property {string} text - The display text of the item.
	 * @property {string} icon - The icon name.
	 * @property {string} route - The route url.
	 * @property {string} isactive - Whether item is active.
	 * @property {Array<ISidebarItem>} [children] - Nested items.
	 */

	/**
	 * The react-i18next hook for translations.
	 */
	const { t } = useTranslation('userModule');

	/**
	 * The react-router-dom navigate hook.
	 */
	const navigate = useNavigate();

	/**
	 * Destructure props.
	 */
	const { id, className, isactive, icon, text, route, children } = props;

	/**
	 * Get current location from react-router-dom.
	 */
	const location = useLocation();

	/**
	 * Handle route change for other sidebar items.
	 */
	const onOtherItemsRouteChange = () => {
		navigate(route);
	};

	/**
	 * Toggle selected class on sidebar item element.
	 * @param {string} elementId - The id of the element.
	 * @param {boolean} selected - Whether to set element as selected.
	 */
	const toggleSelected = (elementId: string, selected: boolean) => {
		const element = document.getElementById(elementId);
		if (element) {
			element.classList.toggle('k-selected', selected);
		}
	};

	/**
	 * Navigates to the given endpoint
	 * @param {string} endpoint - The endpoint to navigate to
	 */
	const navigateTo = (endpoint: string) => (e) => {
		e.preventDefault();
		e.stopPropagation();
		navigate(endpoint);
	};

	/**
	 * Checks if the current path is active
	 * @returns {boolean} - Whether the current path is active
	 */
	const isActivePath = useCallback(() => {
		const path = location.pathname;
		return children?.some((child: any) => child?.route === path);
	}, [children, location.pathname]);

	/**
	 * Counts the number of active children items with isactive === 'true'.
	 * @param {Array} children - The array of children menu items.
	 * @returns {number} The count of active children items.
	 */
	const countActiveChildren = () => {
		let activeChildrenCount = 0;

		if (children && Array.isArray(children)) {
			activeChildrenCount += children.filter((child) => child.isactive === 'true').length;
		}

		return activeChildrenCount;
	};

	/**
	 * Toggles the selected class on the element
	 * based on active path or route match
	 */
	useEffect(() => {
		toggleSelected(id, isActivePath() || route === location?.pathname);
	}, [children, id, isActivePath, location.pathname, route]);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<DrawerItem {...props} className={className || ''}>
			{/*  Render logo if id is 0 */}
			{id === 0 ? (
				<div className='logo-box'>
					<a href={RouteUrl.DASHBOARD}>
						<img src={MainLogo} alt='ACG Logo' />
					</a>
				</div>
			) : (
				// Otherwise render menu item
				<>
					{/* Menu icon */}
					<span className='menu-icon'>
						<ShowIcon name={icon} />
					</span>
					{/* Menu text */}
					{isactive === 'true' ? (
						<div
							role='button'
							onKeyDown={(event) => {
								// Handle enter key press
								if (event.key === 'Enter') {
									onOtherItemsRouteChange();
								}
							}}
							tabIndex={0}
							className={`item-descr-wrap${
								route === location.pathname ? ' dropdown-active' : ''
							}`}
							onClick={() => onOtherItemsRouteChange()}>
							{text}
						</div>
					) : (
						<span className='item-descr-wrap' />
					)}
					{/* Render dropdown if there are children */}
					{children && (
						<Dropdown
							drop='end'
							show={countActiveChildren() > 0}
							onBlur={() => toggleSelected(id, true)}
							className={isactive !== 'true' ? 'no-first-menu' : ''}>
							<Dropdown.Toggle>{/* {props.text} */}</Dropdown.Toggle>

							<Dropdown.Menu>
								{children.map(
									(child: ISidebarItem) =>
										child.isactive === 'true' && (
											<Dropdown.Item
												key={child.id}
												onClick={navigateTo(child.route)}
												className={`${
													location.pathname === child.route
														? 'dropdown-active'
														: ''
												} ${
													isactive !== 'true' &&
													countActiveChildren() === 1
														? 'only-item'
														: ''
												}`}>
												<span>{t(child.text)}</span>
											</Dropdown.Item>
										),
								)}
							</Dropdown.Menu>
						</Dropdown>
					)}
				</>
			)}
		</DrawerItem>
	);
};

export default SidebarCustom;
