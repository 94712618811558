/**
 * DateRangePicker component.
 * @param {Object} props - The props passed to the component.
 * @param {Function} props.getDataRange - Callback function triggered when the date range changes.
 * @param {boolean} props.disabled - Whether the DateRangePicker is disabled or not.
 * @param {object} props.value - The selected date range value.
 * @param {Date} props.min - The minimum date that can be selected.
 * @param {Date} props.max - The maximum date that can be selected.
 * @param {string} props.label - The label text to display.
 * @param {string} props.className - Class name to apply custom styles to the component.
 * @param {boolean} [props.clearable=false] - Whether the date range can be cleared.
 * @param {Function} props.onClear - Callback function triggered when the date range is cleared (if clearable is true).
 * @returns {JSX.Element} The rendered component.
 */

import { memo, useState, useEffect } from 'react';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

import { useAppSelector } from 'hooks/stateHooks';
import { getDataFormat } from 'slices/configSlice';

import { IDateRangePicker } from '../model';
import ShowIcon from '../fontAwesome/ShowIcon';

/**
 * DateRangePicker component.
 * @param {Object} props - The props passed to the component.
 */
const DateRangePickerComponent = ({
	getDataRange,
	disabled,
	value,
	min,
	max,
	label,
	className,
	clearable = false,
	onClear,
}: IDateRangePicker) => {
	/**
	 * State to handle show/hide of date range picker popup.
	 */
	const [showDateRange, setShowDateRange] = useState(false);

	/**
	 * Get date format from redux store.
	 */
	const dateFormat = useAppSelector(getDataFormat);

	/**
	 * Handler when date range changes.
	 * @param {Object} e - Event object
	 */
	const onDateRangeChange = (e: any) => {
		getDataRange(e.value);
		if (e.value.start !== null && e.value.end !== null) {
			setShowDateRange(false);
		}
	};

	/**
	 * Show date range picker popup.
	 */
	const handleOpenDateRange = () => {
		setShowDateRange(true);
	};

	// to close the calendar popover on outside click
	useEffect(() => {
		/**
		 * Handler to check click outside picker and close it.
		 * @param {Object} event - Browser event
		 */
		function handleClickOutsideDate(event) {
			/**
			 * Get date range picker element.
			 */
			const element = document.getElementsByClassName(
				'k-popup k-daterangepicker-popup k-child-animation-container',
			);
			/**
			 * Get component button element.
			 */
			const btnElement = document.getElementsByClassName(
				`default-date-ranger-combo-box ${className}`,
			);

			if (
				element[0] &&
				!element[0].contains(event.target) &&
				!btnElement[0].contains(event.target)
			) {
				/**
				 * Hide date range picker popup when clicked outside.
				 */
				setShowDateRange(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutsideDate);

		return () => {
			document.removeEventListener('mousedown', handleClickOutsideDate);
		};
	}, [className]);

	/**
	 * Clear selected dates. if callback function is there and not disabled. ie. if non editable then no need for clear
	 */
	const handleClear = () => {
		if (onClear && !disabled) {
			onClear();
		}
	};

	return (
		<div className={`default-date-ranger-combo-box ${disabled ? 'disabled' : ''} ${className}`}>
			<label className='form-label'>{label}</label>
			<DateRangePicker
				onChange={(e: any) => onDateRangeChange(e)}
				format={dateFormat}
				className={className}
				value={value}
				disabled={disabled}
				min={min}
				max={max || undefined}
				show={showDateRange}
				onFocus={handleOpenDateRange}
			/>
			{/* Clear button section */}
			{clearable && (value?.start || value?.end) && (
				<span
					className={`close-button clearBtn ${disabled ? 'disabled' : ''}`}
					role='button'
					tabIndex={0}
					title='clear'
					onKeyDown={() => {}}
					onClick={() => handleClear()}>
					<ShowIcon name='faClose' />
				</span>
			)}
		</div>
	);
};

export default memo(DateRangePickerComponent);
