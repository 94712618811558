import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	fileFailureNotificationCount: 0,
	unreadNorificationCount: 0,
	failedNotifications: [],
};

export const fileFailureModuleSlice = createSlice({
	name: 'fileFailureModule',
	initialState,
	reducers: {
		setFileFailureNotificationCount: (state, action: PayloadAction<any>) => {
			state.fileFailureNotificationCount = action.payload;
		},
		setUnreadNotificationCount: (state, action: PayloadAction<any>) => {
			state.unreadNorificationCount = action.payload;
		},
		setFailedNotifications: (state, action: PayloadAction<any>) => {
			state.failedNotifications = action.payload;
		},
	},
});

export const {
	setFileFailureNotificationCount,
	setUnreadNotificationCount,
	setFailedNotifications,
} = fileFailureModuleSlice.actions;

export const getFileFailureNotificationCount = (state: RootState) =>
	state.fileFailureModule.fileFailureNotificationCount;
export const getUnreadNotificationCount = (state: RootState) =>
	state.fileFailureModule.unreadNorificationCount;
export const getFailedNotifications = (state: RootState) =>
	state.fileFailureModule.failedNotifications;

export default fileFailureModuleSlice.reducer;
