import { useEffect, useState } from 'react';

import { axiosPrivateInstance } from 'libs/axios';

import { GET_ALL_ROLE_DATA } from 'utils/serviceUrls';

const useGetAllRoleData = () => {
	const [roleResponseData, setRoleResponseData] = useState<any>([]);
	const [roleloading, setRoleLoading] = useState<boolean>(true);

	const ApiRespone = async () => {
		setRoleLoading(true);

		// eslint-disable-next-line no-unused-vars
		await axiosPrivateInstance
			.post(GET_ALL_ROLE_DATA)
			.then((response: any) => {
				setRoleResponseData(response.output);
				setRoleLoading(false);
			})
			.catch(() => {
				setRoleLoading(false);
			});
	};

	useEffect(() => {
		ApiRespone();
	}, []);

	useEffect(() => {
		ApiRespone();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		roleResponseData,
		roleloading,
	};
};

export default useGetAllRoleData;
