import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	productUpdateStatus: false,
	productStatusUpdate: false,
	encProductId: '',
	productPackingAddResStatus: false,
	productPackingUpdateResStatus: false,
	productPackingDeleteResStatus: false,
	prnUpdateStatus: false,
	packageAddSubmitStatus: false,
	packageUpdateSubmitStatus: false,
	packageDeleteSubmitStatus: false,
	manufacturerGs1Prefix: '',
	productSideBarIsSubmited: {
		general: false,
		productPackaging: false,
		hrf: false,
		prn: false,
		gs1: false,
	},
	productSideBarIsActive: {
		general: true,
		productPackaging: false,
		hrf: false,
		prn: false,
		gs1: false,
	},
	productSideBarValue: '1',
	expanded: ['.0'],
	eLeafletFileName: null,
	iVedaPropertiesStatus: false,
};

export const productModuleSlice = createSlice({
	name: 'productModule',
	initialState,
	reducers: {
		setProductStatusUpdate: (state, action: PayloadAction<any>) => {
			state.productStatusUpdate = action.payload;
		},
		setProductUpdateStatus: (state, action: PayloadAction<any>) => {
			state.productUpdateStatus = action.payload;
		},
		setEncProductId: (state, action: PayloadAction<any>) => {
			state.encProductId = action.payload;
		},
		setProductPackingAddResStatus: (state, action: PayloadAction<any>) => {
			state.productPackingAddResStatus = action.payload;
		},
		setProductPackingUpdateResStatus: (state, action: PayloadAction<any>) => {
			state.productPackingUpdateResStatus = action.payload;
		},
		setProductPackingDeleteResStatus: (state, action: PayloadAction<any>) => {
			state.productPackingDeleteResStatus = action.payload;
		},
		setPrnUpdateStatus: (state, action: PayloadAction<any>) => {
			state.prnUpdateStatus = action.payload;
		},
		setPackageAddSubmitStatus: (state, action: PayloadAction<any>) => {
			state.packageAddSubmitStatus = action.payload;
		},
		setPackageUpdateSubmitStatus: (state, action: PayloadAction<any>) => {
			state.packageUpdateSubmitStatus = action.payload;
		},
		setPackageDeleteSubmitStatus: (state, action: PayloadAction<any>) => {
			state.packageDeleteSubmitStatus = action.payload;
		},
		setManufacturerGs1Prefix: (state, action: PayloadAction<any>) => {
			state.manufacturerGs1Prefix = action.payload;
		},
		setProductSideBarIsSubmited: (state, action: PayloadAction<any>) => {
			state.productSideBarIsSubmited = action.payload;
		},
		setProductSideBarIsActive: (state, action: PayloadAction<any>) => {
			state.productSideBarIsActive = action.payload;
		},
		setProductSideBarValue: (state, action: PayloadAction<any>) => {
			state.productSideBarValue = action.payload;
		},
		setExpanded: (state, action: PayloadAction<any>) => {
			state.expanded = action.payload;
		},
		setELeafletFileName: (state, action: PayloadAction<any>) => {
			state.eLeafletFileName = action.payload;
		},
		setIVedaPropertiesStatus: (state, action: PayloadAction<any>) => {
			state.iVedaPropertiesStatus = action.payload;
		},
	},
});

export const {
	setProductUpdateStatus,
	setProductStatusUpdate,
	setEncProductId,
	setProductPackingAddResStatus,
	setProductPackingUpdateResStatus,
	setProductPackingDeleteResStatus,
	setPrnUpdateStatus,
	setPackageAddSubmitStatus,
	setPackageUpdateSubmitStatus,
	setPackageDeleteSubmitStatus,
	setManufacturerGs1Prefix,
	setProductSideBarIsSubmited,
	setProductSideBarIsActive,
	setProductSideBarValue,
	setExpanded,
	setELeafletFileName,
	setIVedaPropertiesStatus,
} = productModuleSlice.actions;

export const getProductUpdateStatus = (state: RootState) => state.productModule.productUpdateStatus;
export const getExpanded = (state: RootState) => state.productModule.expanded;
export const getProductStatusUpdate = (state: RootState) => state.productModule.productStatusUpdate;
export const getEncProductId = (state: RootState) => state.productModule.encProductId;
export const getProductPackingAddResStatus = (state: RootState) =>
	state.productModule.productPackingAddResStatus;
export const getProductPackingUpdateResStatus = (state: RootState) =>
	state.productModule.productPackingUpdateResStatus;
export const getProductPackingDeleteResStatus = (state: RootState) =>
	state.productModule.productPackingDeleteResStatus;

export const getPrnUpdateStatus = (state: RootState) => state.productModule.prnUpdateStatus;
export const getManufacturerGs1Prefix = (state: RootState) =>
	state.productModule.manufacturerGs1Prefix;
export const getProductSideBarIsSubmited = (state: RootState) =>
	state.productModule.productSideBarIsSubmited;
export const getProductSideBarIsActive = (state: RootState) =>
	state.productModule.productSideBarIsActive;
export const getProductSideBarValue = (state: RootState) => state.productModule.productSideBarValue;

export default productModuleSlice.reducer;
export const getPackageAddSubmitStatus = (state: RootState) =>
	state.productModule.packageAddSubmitStatus;
export const getPackageUpdateSubmitStatus = (state: RootState) =>
	state.productModule.packageUpdateSubmitStatus;
export const getPackageDeleteSubmitStatus = (state: RootState) =>
	state.productModule.packageDeleteSubmitStatus;
export const getELeafletFileName = (state: RootState) => state.productModule.eLeafletFileName;
export const getIVedaPropertiesStatus = (state: RootState) =>
	state.productModule.iVedaPropertiesStatus;
