import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	loginDataStatus: false,
	userLoginData: null,
	loginValue: null,
	themeValue: false,
	userSavedLanguageValue: '',
};

export const userLoginDataSlice = createSlice({
	name: 'loginData',
	initialState,
	reducers: {
		setUserLoginData: (state, action: PayloadAction<any>) => {
			state.userLoginData = action.payload;
		},
		setUserLoginDataStatus: (state, action: PayloadAction<any>) => {
			state.loginDataStatus = action.payload;
		},
		setLoginValue: (state, action: PayloadAction<any>) => {
			state.loginValue = action.payload;
		},
		setThemeValue: (state, action: PayloadAction<any>) => {
			state.themeValue = action.payload;
		},
		setUserSavedLanguageValue: (state, action: PayloadAction<any>) => {
			state.userSavedLanguageValue = action.payload;
		},
	},
});

export const {
	setUserLoginData,
	setLoginValue,
	setUserLoginDataStatus,
	setThemeValue,
	setUserSavedLanguageValue,
} = userLoginDataSlice.actions;

export const getUserLoginData = (state: RootState) => JSON.parse(state.loginData.userLoginData);
export const getLoginValue = (state: RootState) => state.loginData.loginValue;
export const getUserLoginDataStatus = (state: RootState) => state.loginData.loginDataStatus;
export const getThemeValue = (state: RootState) => state.loginData.themeValue;
export const getUserSavedLanguageValue = (state: RootState) =>
	state.loginData.userSavedLanguageValue;

export default userLoginDataSlice.reducer;
