import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_LOCATION_URL } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useUpdateLocation = (locationData: any, isSubmitted: boolean, setIsSubmitted: any) => {
	const { t } = useTranslation('locationModule');

	const params = {
		location: locationData.locationName,
		gln: locationData.gln,
		sgln: locationData.sgln,
		sglnExtension: locationData.sglnExtension,
		encParentLocationId: locationData.encParentLocationId,
		encLocationRoleId: locationData.encLocationRoleId,
		locationOwnerName: locationData.locationOwnerName,
		encLocationId: locationData.encLocationId,
	};
	const [updateLocationLoading, setUpdateLocationLoading] = useState<boolean>(false);
	const [updateLocationResponse, setUpdateLocationResponse] = useState<any>({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (isSubmitted) {
			setUpdateLocationLoading(true);
			axiosPrivateInstance
				.post(UPDATE_LOCATION_URL, params)
				.then((response: any) => {
					setUpdateLocationResponse(response);
					if (response) {
						if (response?.status) {
							setUpdateLocationLoading(false);
							dispatch(setMessage(t('LOCATION_UPDATED_SUCCESSFULLY')));
							dispatch(setShowNotification(true));
							dispatch(setNotificationType('success'));
						} else {
							setUpdateLocationLoading(false);
							dispatch(
								setMessage(
									t(response.output.resultText || 'LOCATION_UPDATING_FAILED'),
								),
							);
							dispatch(setShowNotification(true));
							dispatch(setNotificationType('error'));
						}
					} else {
						setUpdateLocationLoading(false);
					}

					setUpdateLocationLoading(false);
				})
				.catch(() => {
					setUpdateLocationLoading(false);
				});
		}

		return () => {
			setIsSubmitted(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		updateLocationLoading,
		updateLocationResponse,
	};
};

export default useUpdateLocation;
